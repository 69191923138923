import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';
import { DatePicker } from 'material-ui-pickers';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import AmountBox from './AmountBox';
import DeliveryBox from './offerPage/DeliveryBox';
import InfoBox from './offerPage/InfoBox';
import ActionsBox from './offerPage/ActionsBox';
import DateDisplay from '../../ui/DateDisplay';
import Loader from '../../ui/Loader';
import GoBack from '../../ui/GoBack';
import { getColorFromIndex } from '../../../helpers';

const styles = theme => ({
  root: {
    '& $panelBody': {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  panelBody: {},
  panelHeading: {
    marginBottom: '-50px',
    zIndex: 2,

    [theme.breakpoints.down('xs')]: {
      marginBottom: '-40px',
    },
  },
  heading: {
    marginTop: '0.5rem',
    marginRight: '10px',
  },
  closeAnOffer: {
    color: theme.palette.error.dark,
  },
});

class OfferPage extends React.Component {
  state = {
    menuEl: null,
  };

  // Date picker
  handleDateChange = date => {
    const { onExpiresChange } = this.props.actionHandlers;

    onExpiresChange(date);
  };

  openDatePicker = () => {
    this.picker.open();
    this.onMenuClose();
  };

  // Menu open
  onMenuOpen = event => {
    this.setState({ menuEl: event.currentTarget });
  };

  onMenuClose = () => {
    this.setState({ menuEl: null });
  };

  onGoBack = () => {
    const { history, location } = this.props;
    const locState = location.state;

    history.push((locState && locState.backTo) || '/offers');
  };

  render() {
    const {
      offer,
      user,
      isLoading,
      isRequesting,
      actionHandlers,
      classes,
      intl,
    } = this.props;

    const {
      onBuy,
      onSell,
      onDiscard,
      onDeliveryStarted,
      onCancel,
      onDelivery,
      onDeliveryConfirmed,
      onBuyerCancel,
      onSellerCancel,
      onBuyerRestore,
      onSellerRestore,
    } = actionHandlers;

    const {
      buyer_id,
      seller_id,
      seller,
      elevator,
      region,
      city,
      culture,
      created_at,
      tonnage,
      info,
      offer_state,
      delivered,
      confirmed,
      bought_at,
      buyer_cancel_request,
      seller_cancel_request,
      expires_at,
      delivery_started_at,
    } = offer;

    const isUserBuyer = user.user_type === 'buyer';
    const isOfferBuyer = !!buyer_id && user.id === buyer_id;
    const isOfferSeller = !!seller_id && user.id === seller_id;

    const createdAt = moment(created_at);
    const expiresAt = moment(expires_at);
    const maxExpiresDate = moment(expires_at).add(25, 'days');

    const tLetter = intl.formatMessage({ id: 'offers.t' });
    const siteTitle = intl.formatMessage({ id: 'siteTitle' });

    const { menuEl } = this.state;
    const menuOpen = Boolean(menuEl);

    return (
      <React.Fragment>
        {isLoading && <Loader />}
        {!isLoading && seller && (
          <div
            className={`readmin-panel righticonmenu withoutline ${
              classes.root
            }`}
          >
            <Helmet>
              <title>
                {`${culture.title} ${tonnage}${tLetter}. | ${siteTitle}`}
              </title>
            </Helmet>
            <div className={`panel-heading pl-2 ${classes.panelHeading}`}>
              <div className="panel-heading-buttons mr-a ml-0">
                <GoBack onClick={this.onGoBack} />
              </div>
              {(offer_state === 'active' || offer_state === 'bought') &&
                isOfferSeller && (
                  <div className="panel-heading-buttons">
                    <IconButton
                      aria-label="More"
                      aria-owns={menuOpen ? 'long-menu' : undefined}
                      aria-haspopup="true"
                      onClick={this.onMenuOpen}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={menuEl}
                      getContentAnchorEl={null}
                      open={menuOpen}
                      onClose={this.onMenuClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      {offer_state === 'active' && (
                        <MenuItem
                          onClick={this.openDatePicker}
                          disabled={isRequesting}
                        >
                          <FormattedMessage id="offers.extendTheOffer" />
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={onCancel}
                        className={classes.closeAnOffer}
                        disabled={isRequesting}
                      >
                        <FormattedMessage id="offers.closeOffer" />
                      </MenuItem>
                    </Menu>
                    <DatePicker
                      hidden
                      minDate={expiresAt}
                      maxDate={maxExpiresDate}
                      format="d MMM YYYY"
                      value={maxExpiresDate}
                      onChange={this.handleDateChange}
                      ref={node => {
                        this.picker = node;
                      }}
                      cancelLabel={<FormattedMessage id="forms.discard" />}
                      okLabel={<FormattedMessage id="forms.submit" />}
                      todayLabel={intl.formatMessage({
                        id: 'common.today',
                      })}
                    />
                  </div>
                )}
            </div>
            <div className={`panel-body ${classes.panelBody}`}>
              <div className="row">
                <div className="d-none d-xl-block col-xl-1" />
                <div className="col-md-7 col-xl-6">
                  <CardContent className="py-2">
                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                      <div className={`h1 ${classes.heading}`}>
                        {culture.title}
                      </div>
                      <DateDisplay date={createdAt.format('DD.MM.YY, HH:mm')} />
                    </div>
                  </CardContent>
                  <CardHeader
                    avatar={
                      <Avatar
                        src={seller.avatar}
                        alt={seller.name}
                        style={{
                          backgroundColor: !seller.avatar
                            ? getColorFromIndex(seller.id)[600]
                            : null,
                        }}
                        component={Link}
                        to={`/user${seller.id}`}
                      >
                        {seller.name ? seller.name[0] : <PersonIcon />}
                      </Avatar>
                    }
                    title={
                      <Typography
                        component={Link}
                        to={`/user${seller.id}`}
                        color="default"
                      >
                        {seller.name}
                      </Typography>
                    }
                    subheader={
                      <Typography
                        component="a"
                        href={`tel:${seller.phone}`}
                        color="secondary"
                      >
                        {seller.phone}
                      </Typography>
                    }
                  />
                  <DeliveryBox
                    deliveryStartedAt={delivery_started_at}
                    offerState={offer_state}
                    delivered={delivered}
                    tonnage={tonnage}
                    confirmed={confirmed}
                    isOfferBuyer={isOfferBuyer}
                    isOfferSeller={isOfferSeller}
                    isRequesting={isRequesting}
                    isBuyerCancelRequest={buyer_cancel_request}
                    isSellerCancelRequest={seller_cancel_request}
                    onDelivery={onDelivery}
                    onDeliveryConfirmed={onDeliveryConfirmed}
                    onSell={onSell}
                  />
                  {!delivery_started_at && offer_state === 'sold' && (
                    <CardContent>
                      <Typography
                        variant="button"
                        className="text-center mt-3 mb-3"
                      >
                        <FormattedMessage id="offers.dealCompleted" />
                      </Typography>
                    </CardContent>
                  )}
                  <AmountBox offer={offer} showLabel />
                  <div>
                    <CardContent className="d-flex justify-content-between">
                      <div className="text-black-50 mr-3">
                        <FormattedMessage id="offers.region" />
                      </div>
                      <div>{region.title}</div>
                    </CardContent>
                    <Divider />
                    {elevator && (
                      <CardContent className="d-flex justify-content-between">
                        <div className="text-black-50 mr-3">
                          <FormattedMessage id="offers.elevator" />
                        </div>
                        <div>{elevator.title}</div>
                      </CardContent>
                    )}
                    {city && (
                      <CardContent className="d-flex justify-content-between py-3">
                        <div className="text-black-50 mr-3">
                          <FormattedMessage id="offers.city" />
                        </div>
                        <div>{city}</div>
                      </CardContent>
                    )}
                    <Divider />
                  </div>
                  {!!info && <CardContent>{info}</CardContent>}
                  <CardContent className="pb-0">
                    <InfoBox
                      offer={offer}
                      isOfferBuyer={isOfferBuyer}
                      isOfferSeller={isOfferSeller}
                      isBuyerCancelRequest={buyer_cancel_request}
                      isSellerCancelRequest={seller_cancel_request}
                    />
                  </CardContent>
                  <CardActions className="mt-2 flex-wrap">
                    <ActionsBox
                      offerState={offer_state}
                      isUserBuyer={isUserBuyer}
                      isOfferBuyer={isOfferBuyer}
                      isOfferSeller={isOfferSeller}
                      isRequesting={isRequesting}
                      isBuyerCancelRequest={buyer_cancel_request}
                      isSellerCancelRequest={seller_cancel_request}
                      hasBuyer={!!bought_at}
                      onBuy={onBuy}
                      onDiscard={onDiscard}
                      onSell={onSell}
                      onDeliveryStarted={onDeliveryStarted}
                      onBuyerCancel={onBuyerCancel}
                      onSellerCancel={onSellerCancel}
                      onBuyerRestore={onBuyerRestore}
                      onSellerRestore={onSellerRestore}
                    />
                  </CardActions>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

OfferPage.propTypes = {
  offer: PropTypes.shape({
    seller: PropTypes.object,
  }).isRequired,
  isLoading: PropTypes.bool,
  isRequesting: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.number,
    user_type: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({
    heading: PropTypes.string,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  actionHandlers: PropTypes.shape({
    onBuy: PropTypes.func,
    onExpiresChange: PropTypes.func,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

OfferPage.defaultProps = {
  isLoading: false,
  isRequesting: false,
  actionHandlers: {},
};

export default compose(
  withStyles(styles),
  injectIntl,
)(OfferPage);
