import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import blue from '@material-ui/core/colors/blue';
import { FormattedMessage } from 'react-intl';

const statusColors = {
  bought: yellow[700],
  delivery: green[500],
  sold: blue[500],
  cancelled: red[400],
};

const styles = theme => ({
  statusBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.25rem',
    alignItems: 'center',
  },
  statusBox: {
    display: 'flex',
  },
  statusIcon: {
    width: '0.5rem',
    height: '0.5rem',
    borderRadius: '100em',
    backgroundColor: theme.palette.grey[500],
    alignSelf: 'center',
    marginRight: '6px',
  },
});

const StatusBox = ({ offerState, classes }) => {
  const isActiveOffer = offerState === 'active';

  return !isActiveOffer ? (
    <Typography className={classes.statusBox} color="textSecondary">
      <i
        className={classes.statusIcon}
        style={{ backgroundColor: statusColors[offerState] }}
      />
      <FormattedMessage id={`offers.${offerState}`} />
    </Typography>
  ) : null;
};

StatusBox.propTypes = {
  offerState: PropTypes.string.isRequired,

  classes: PropTypes.shape({
    statusBox: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(StatusBox);
