import user from './userAPI';
import prices from './pricesAPI';
import references from './referencesAPI';
import offers from './offersAPI';
import exchange from './exchangeAPI';

export default {
  user,
  prices,
  references,
  offers,
  exchange,
};
