import React from 'react';
import ContactUs from '../components/pages/contactUs.js';

const Contact = () => (
  <div>
    <ContactUs />
  </div>
)

export default Contact;
