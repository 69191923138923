import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    height: '2px',

    '&:not($light)': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  light: {},
});

const Heading = ({ children, className, light, classes, ...rest }) => (
  <>
    {children}
    <Divider
      className={classNames(
        classes.root,
        { [classes.light]: light },
        className,
      )}
      light={light}
      {...rest}
    />
  </>
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  light: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
};

Heading.defaultProps = {
  className: '',
  light: false,
};

export default withStyles(styles)(Heading);
