import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit,
    flexWrap: 'wrap',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    marginLeft: 'auto',
  },
  titleBox: {
    flex: '0 0 auto',
    maxWidth: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    [theme.breakpoints.down('xs')]: {
      paddingTop: '15px',
    },
  },
  title: {
    marginRight: '20px',
  },
});

const EnhancedTableToolbar = props => {
  const { classes, title, subtitle, actions } = props;

  return (
    <Toolbar className={classNames(classes.root)}>
      <div className={classes.titleBox}>
        <Typography inline variant="h6" className={classes.title}>
          {title}
        </Typography>
        {subtitle && (
          <Typography inline variant="subtitle1" component="span">
            {subtitle}
          </Typography>
        )}
      </div>
      <div className={classes.actions}>{actions}</div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
    actions: PropTypes.string,
  }).isRequired,
  title: PropTypes.element.isRequired,
  subtitle: PropTypes.element,
  actions: PropTypes.element,
};

EnhancedTableToolbar.defaultProps = {
  subtitle: null,
  actions: null,
};

export default withStyles(toolbarStyles)(EnhancedTableToolbar);
