import axios from 'axios';

import { config } from '../config';

const WITH = 'users,culture,currency,elevator,region';

const list = params =>
  axios
    .get(`${config.apiUrl}/v2/offers`, {
      params: {
        with: WITH,
        ...params,
      },
    })
    .then(response => response.data);

const userList = (id, params) =>
  axios
    .get(`${config.apiUrl}/v2/users/${id}/offers`, {
      params: {
        with: WITH,
        limit: 9999,
        ...params,
      },
    })
    .then(response => response.data.offers);

const view = id =>
  axios
    .get(`${config.apiUrl}/v2/offers/${id}`, {
      params: { with: WITH },
    })
    .then(response => response.data);

const patch = (id, params) =>
  axios
    .patch(`${config.apiUrl}/v2/offers/${id}`, params)
    .then(response => response.data);

const create = params =>
  axios
    .post(`${config.apiUrl}/v2/offers`, params)
    .then(response => response.data);

export default {
  list,
  userList,
  view,
  patch,
  create,
};
