// Needs for sidebar navigation, functional
// NavLink throwing error "cannot have refs"

import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class NavLinkStatefull extends Component {
  render() {
    const { children, ...rest } = this.props;

    return <NavLink {...rest}>{children}</NavLink>;
  }
}

export default NavLinkStatefull;
