import React from 'react';
import Blog from '../components/pages/blog.js';

const BlogPage = () => (
  <div>
    <Blog />
  </div>
)

export default BlogPage;
