import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MoreIcon from '@material-ui/icons/More';
// import ForumIcon from '@material-ui/icons/Forum';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import SettingsIcon from '@material-ui/icons/Settings';
import TimelineIcon from '@material-ui/icons/Timeline';
import AddIcon from '@material-ui/icons/Add';
import { FormattedMessage } from 'react-intl';

import Divider from '../ui/Divider';
import NavLinkStatefull from './NavLinkStatefull';
// import DemoRoutesListItem from '../../demo/routers/routesList';

const SideNav = ({ onListItemClick, userId, userType }) => (
  <List className="nav-menu" component="nav">
    {userType === 'seller' && (
      <>
        <NavLinkStatefull to="/create-offer" exact>
          <ListItem onClick={onListItemClick} button>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText
              primary={<FormattedMessage id="offers.createOffer" />}
            />
          </ListItem>
        </NavLinkStatefull>
        <Divider />
      </>
    )}
    <NavLinkStatefull to="/offers" exact>
      <ListItem onClick={onListItemClick} button>
        <ListItemIcon>
          <MoreIcon />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="nav.offers" />} />
      </ListItem>
    </NavLinkStatefull>
    {/* <NavLinkStatefull to="/chats" exact>
      <ListItem onClick={onListItemClick} button>
        <ListItemIcon>
          <ForumIcon />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="nav.chats" />} />
      </ListItem>
    </NavLinkStatefull> */}
    <NavLinkStatefull to="/prices" exact>
      <ListItem onClick={onListItemClick} button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="nav.priceList" />} />
      </ListItem>
    </NavLinkStatefull>
    <NavLinkStatefull to="/exchange" exact>
      <ListItem onClick={onListItemClick} button>
        <ListItemIcon>
          <TimelineIcon />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="nav.exchange" />} />
      </ListItem>
    </NavLinkStatefull>
    <Divider />
    <NavLinkStatefull to={`/user${userId}`} exact>
      <ListItem onClick={onListItemClick} button>
        <ListItemIcon>
          <AccountCircleIcon />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="nav.profile" />} />
      </ListItem>
    </NavLinkStatefull>
    {/* <NavLinkStatefull to="/settings" exact>
      <ListItem onClick={onListItemClick} button>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id="nav.settings" />} />
      </ListItem>
    </NavLinkStatefull> */}

    {/* <Divider /> */}
    {/* <DemoRoutesListItem /> */}
  </List>
);

SideNav.propTypes = {
  onListItemClick: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  userType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  userId: state.user.id,
  userType: state.user.user_type,
});

export default withRouter(connect(mapStateToProps)(SideNav));
