/* eslint-disable no-use-before-define */

import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_LOADED,
  USER_ACTIVATED,
  USER_EDITED_PROFILE,
  USER_EDITED_SETTINGS,
  USER_UPLOADED_AVATAR,
} from '../types';

import api from '../../api';
import { blob_to_b64, utf8_to_b64 } from '../../helpers';

export const userLoggedIn = (user, settings) => ({
  type: USER_LOGGED_IN,
  user,
  settings,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const userLoaded = () => ({
  type: USER_LOADED,
});

export const userActivated = user => ({
  type: USER_ACTIVATED,
  user,
});

export const userEditedProfile = user => ({
  type: USER_EDITED_PROFILE,
  user,
});

export const userEditedSettings = settings => ({
  type: USER_EDITED_SETTINGS,
  settings,
});

export const userUploadedAvatar = url => ({
  type: USER_UPLOADED_AVATAR,
  url,
});

export const login = authdata => dispatch =>
  api.user
    .login(authdata)
    .then(({ user: fetchedUser, settings }) => {
      const user = { ...fetchedUser, authdata };

      localStorage.setItem('user', JSON.stringify({ authdata }));
      dispatch(userLoggedIn(user, settings));
    })
    .catch(error => {
      dispatch(logout());
      throw error;
    });

export const logout = () => dispatch => {
  localStorage.removeItem('user');
  dispatch(userLoggedOut());
};

export const activateProfile = (id, data, credentials, avatar) => dispatch => {
  const activateData = {
    name: data.name,
    phone: data.phone,
    state: 'active',
  };

  return api.user.activate(id, activateData).then(user => {
    dispatch(userActivated(user));
    dispatch(editProfile(id, data, credentials));

    if (avatar) dispatch(uploadAvatar(id, avatar));
  });
};

export const editProfile = (id, data, credentials) => dispatch =>
  api.user.edit(id, data).then(userWithAvatar => {
    const { avatar, ...user } = userWithAvatar;

    if (data.password || user.email !== credentials.email) {
      const password = data.password || credentials.password;
      const authdata = utf8_to_b64(`${user.email}:${password}`);
      user.authdata = authdata;

      localStorage.setItem('user', JSON.stringify({ authdata }));
    }

    dispatch(userEditedProfile(user));
  });

export const uploadAvatar = (id, image) => dispatch =>
  api.user.uploadAvatar(id, image).then(() => {
    blob_to_b64(image).then(base64image => {
      dispatch(userUploadedAvatar(base64image));
    });
  });

export const editSettings = settings => dispatch =>
  api.user.settings(settings).then(account => {
    dispatch(userEditedSettings(account.settings));
  });
