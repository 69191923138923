import React from 'react';
import { Router, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CSSTransition } from 'react-transition-group';

import { history } from '../../inits';
import { PrivateRoute } from './PrivateRoute';
import ScrollToTop from './ScrollToTop';
import LoginPage from '../auth/LoginPage';
import ForgotPasswordPage from '../auth/ForgotPasswordPage';
import Authorized from '../layout/Authorized';
import ActivatePage from '../profile/ActivatePage';

const AuthRouter = props => {
  const { user } = props;
  const isLoggedIn = !!user.authdata;
  const isInvited = user.state === 'invited';
  const isLoaded = user.loaded;

  const RouterComponent = (
    <div className="main-wrapper">
      <Router history={history}>
        <ScrollToTop>
          <Switch>
            <PrivateRoute
              isAllowed={!isLoggedIn}
              path="/login"
              component={LoginPage}
              redirectTo="/"
            />
            <PrivateRoute
              isAllowed={!isLoggedIn}
              path="/forgot-password"
              component={ForgotPasswordPage}
              redirectTo="/"
            />
            <PrivateRoute
              isAllowed={isLoggedIn && isInvited}
              path="/profile/activate"
              component={ActivatePage}
              redirectTo="/"
            />

            {isInvited && (
              <PrivateRoute
                isAllowed={isLoggedIn}
                path="/"
                component={() => <Redirect to="/profile/activate" />}
              />
            )}

            <PrivateRoute
              isAllowed={isLoggedIn}
              path="/"
              component={Authorized}
            />
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );

  const Loader = (
    <div className="center-absolute">
      <CircularProgress size={80} thickness={2} />
    </div>
  );

  return (
    <div>
      {!isLoaded && Loader}
      <CSSTransition
        in={isLoaded}
        timeout={500}
        classNames={{
          enter: 'animated',
          enterActive: 'fadeIn',
          leave: 'animated',
          leaveActive: 'fadeOut',
        }}
      >
        <div>{isLoaded && RouterComponent}</div>
      </CSSTransition>
    </div>
  );
};

AuthRouter.propTypes = {
  user: PropTypes.shape({
    authdata: PropTypes.string,
  }).isRequired,
};

export default connect(state => ({ user: state.user }))(AuthRouter);
