import PropTypes from 'prop-types';

const TabsContainer = ({ index, children: childrenProp }) => {
  const children = childrenProp.flat();
  const child = children.find((child, i) => index === i);

  return child || null;
};

TabsContainer.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default TabsContainer;
