import React from 'react';
import MaterialDivider from '@material-ui/core/Divider';

const Divider = () => (
  <div className="menudivider">
    <MaterialDivider />
  </div>
);

export default Divider;
