import { EXCHANGE_REQUEST, EXCHANGE_SUCCESS, EXCHANGE_FAILURE } from '../types';

import api from '../../api';
import { snakeToCamel } from '../../helpers';

export const exchangeRequest = () => ({
  type: EXCHANGE_REQUEST,
});

export const exchangeSuccess = exchange => ({
  type: EXCHANGE_SUCCESS,
  exchange,
});

export const exchangeFailure = error => ({
  type: EXCHANGE_FAILURE,
  error,
});

export const fetchExchange = () => dispatch => {
  dispatch(exchangeRequest());

  const marketsPromise = api.exchange.markets();
  const currencyRatesPromise = api.exchange.currencyRates();

  return Promise.all([marketsPromise, currencyRatesPromise])
    .then(values => {
      const exchange = values.reduce((accu, item) => {
        const key = Object.keys(item)[0];
        const value = Object.values(item)[0];

        const camelKey = snakeToCamel(key);

        // it's fine to reassign accumularor in reduce
        // eslint-disable-next-line no-param-reassign
        accu[camelKey] = value;
        return accu;
      }, {});

      dispatch(exchangeSuccess(exchange));
    })
    .catch(error => {
      dispatch(exchangeFailure(error));
    });
};
