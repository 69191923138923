import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import DemoRoutes from '../../demo/routers/routesComponent';

import Page404 from './Page404';
import OffersListPage from '../offers/OffersListPage';
import OfferViewPage from '../offers/OfferViewPage';
// import ChatsPage from '../chats/ChatsPage';
import PricesPage from '../prices/PricesPage';
import ExchangePage from '../exchange/ExchangePage';
import SettingsPage from '../profile/SettingsPage';
import ProfilePage from '../profile/ProfilePage';
import UserPage from '../profile/UserPage';
import UserOffersPage from '../offers/UserOffersPage';
import CreateOfferPage from '../offers/CreateOfferPage';

const RoutesComponent = ({ userId }) => (
  <Switch>
    <Route path="/" exact render={() => <Redirect to="/offers" />} />
    <Route path="/offers" exact component={OffersListPage} />
    <Route path="/offers/:id" exact component={OfferViewPage} />
    {/* <Route path="/chats" component={ChatsPage} /> */}
    <Route path="/prices" component={PricesPage} />
    <Route path="/exchange" component={ExchangePage} />
    <Route path="/settings" component={SettingsPage} />

    <Route
      exact
      path="/profile"
      render={() => <Redirect to={`/user${userId}`} />}
    />

    <Route
      exact
      path="/user:id"
      render={props =>
        +userId === +props.match.params.id ? (
          <ProfilePage {...props} />
        ) : (
          <UserPage {...props} />
        )
      }
    />

    <Route path="/user:id/offers/:state" component={UserOffersPage} />
    <Route path="/create-offer" exact component={CreateOfferPage} />

    <DemoRoutes />

    <Route component={Page404} />
  </Switch>
);

RoutesComponent.propTypes = {
  userId: PropTypes.number.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
};

const mapStateToProps = state => ({
  userId: state.user.id,
});

export default withRouter(connect(mapStateToProps)(RoutesComponent));
