import React from 'react';
import { withStyles } from '@material-ui/core';

import LogoSvg from '../ui/LogoSvg';

const styles = theme => ({
  svg: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    marginBottom: '-30px',
    display: 'block',
  },
  img: {
    fill: theme.palette.secondary.main,
  },
  bg: {
    // fill: 'none',
  },
});

const Logo = ({ classes }) => (
  <div>
    <LogoSvg
      className={classes.svg}
      imgProps={{
        className: classes.img,
      }}
      bgProps={{
        className: classes.bg,
      }}
    />
  </div>
);

export default withStyles(styles)(Logo);
