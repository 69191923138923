import React from 'react';
import LayoutDefault from '../components/page-layouts/layoutDefault.js';

const PageLayoutDefault = () => (
  <div>
    <LayoutDefault />
  </div>
);

export default PageLayoutDefault;
