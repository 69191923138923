import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const styles = theme => ({
  timeBox: {
    position: 'relative',
    paddingLeft: '24px',
  },
  timeIcon: {
    position: 'absolute',
    left: 0,
    top: '1px',
    width: '0.75em',
    height: '0.75em',
    fill: theme.palette.secondary.light,
  },
});

const DateDisplay = ({ classes, date, ...props }) => (
  <div className={classes.timeBox} {...props}>
    <AccessTimeIcon className={classes.timeIcon} />
    <Typography component="time" color="textSecondary">
      {date}
    </Typography>
  </div>
);

DateDisplay.propTypes = {
  date: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    timeBox: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(DateDisplay);
