import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { createMuiTheme } from '@material-ui/core/styles'; // v1.x

const PRIMARY_COLOR = '#6abf40';
const SECONDARY_COLOR = '#147d14';
const LIGHT_GRAY = '#e0e0e0';

export const v0MuiTheme = getMuiTheme({
  palette: {
    primary1Color: PRIMARY_COLOR,
    accent1Color: SECONDARY_COLOR,
  },
});

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
      contrastText: '#fff',
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
    typography: {
      useNextVariants: true,
      suppressDeprecationWarnings: true,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480 + 0.5,
      md: 768 - 0.5,
      lg: 992 - 0.5,
      xl: 1200 - 0.5,
    },
  },
  zIndex: {
    modal: 1401,
    snackbar: 1450,
  },
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottom: `1px solid ${LIGHT_GRAY}`,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `1px solid ${LIGHT_GRAY}`,
          '@media (hover: none)': {
            borderBottom: `1px solid ${LIGHT_GRAY}`,
          },
        },
      },
    },
    MuiFormControl: {
      root: {
        width: '256px',
        maxWidth: '100%',
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: '1rem',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          '0 4px 16px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02)',
      },
    },
    MuiCardHeader: {
      subheader: {
        fontWeight: '400',
      },
      avatar: {
        '& a:hover, & a:focus': {
          textDecoration: 'none',
          color: '#fff',
        },
      },
    },
    MuiTypography: {
      overline: {
        lineHeight: '1.5',
        letterSpacing: 'normal',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '0.75rem',
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '0.875rem',
      },
      body: {
        fontSize: '1rem',
      },
    },
    MuiTablePagination: {
      root: {
        fontSize: '0.875rem',
      },
      toolbar: {
        height: 'auto',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
      },
      spacer: {
        display: 'none',
      },
      caption: {
        fontSize: 'inherit',
        marginRight: '20px',
      },
      actions: {
        marginLeft: '0',
      },
    },
  },
});
