import React from 'react';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const styles = () => ({
  amountBox: {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  amountItem: {
    fontSize: '1.125rem',
    textAlign: 'center',
    minWidth: '0',

    '&:first-child': {
      flexShrink: 0,
    },
  },
  amountMeterage: {
    whiteSpace: 'nowrap',
  },
  verticalDivider: {
    width: '1px',
    height: '100%',
    minHeight: '30px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    marginLeft: '10px',
    marginRight: '10px',
    flexShrink: '0',
    alignSelf: 'center',
  },
});

const AmountBox = ({
  classes,
  offer: { tonnage, currency, price, total_price },
  showLabel,
  amountItemTypoClass,
}) => (
  <CardContent className={classes.amountBox}>
    <div className={classes.amountItem}>
      <Typography variant="h6" component="div" className={amountItemTypoClass}>
        {`${tonnage} `}
        <FormattedMessage id="offers.t" />
      </Typography>
      {showLabel && (
        <Typography variant="overline" color="textSecondary">
          <FormattedMessage id="offers.tonnage" />
        </Typography>
      )}
    </div>
    <span className={classes.verticalDivider} />
    <div className={classes.amountItem}>
      <Typography variant="h6" component="div" className={amountItemTypoClass}>
        {price}
        <span className={classes.amountMeterage}>
          {` `}
          {currency.signature}
        </span>
      </Typography>
      {showLabel && (
        <Typography variant="overline" color="textSecondary">
          <FormattedMessage id="offers.pricePerTon" />
        </Typography>
      )}
    </div>
    <span className={classes.verticalDivider} />
    <div className={classes.amountItem}>
      <Typography variant="h6" component="div" className={amountItemTypoClass}>
        {total_price}
        <span className={classes.amountMeterage}>
          {` `}
          {currency.signature}
        </span>
      </Typography>
      {showLabel && (
        <Typography variant="overline" color="textSecondary">
          <FormattedMessage id="offers.totalСost" />
        </Typography>
      )}
    </div>
  </CardContent>
);

AmountBox.propTypes = {
  classes: PropTypes.shape({
    amountBox: PropTypes.string,
  }).isRequired,
  offer: PropTypes.shape({
    tonnage: PropTypes.number,
  }).isRequired,
  showLabel: PropTypes.bool,
  amountItemTypoClass: PropTypes.string,
};

AmountBox.defaultProps = {
  showLabel: false,
  amountItemTypoClass: '',
};

export default withStyles(styles)(AmountBox);
