import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { withFormik } from 'formik';
import { withSnackbar } from 'notistack';
import { DatePicker } from 'material-ui-pickers';
import moment from 'moment';

import Autocomplete from '../common/Autocomplete';

import {
  getReferences,
  getSortedCultures,
  getCurrencies,
  getRegions,
  getElevators,
} from '../../store/selectors';
import { yup } from '../../inits';
import api from '../../api';

const styles = theme => ({
  root: {
    '& $panelBody': {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  panelBody: {},
  heading: {
    marginTop: '0.5rem',
    marginRight: '10px',
  },
  textField: {
    width: '360px',
  },
  row8: {
    marginLeft: '-8px',
    marginRight: '-8px',
  },
  verticalDivider: {
    width: '1.5px',
    marginLeft: '-0.75px',
    marginRight: '-0.75px',
    marginTop: '20px',
    alignSelf: 'stretch',
    backgroundColor: theme.palette.grey[300],
  },
});

const filterElevatorsByRegion = (elevators, regionId) =>
  elevators.filter(elevator => elevator.region.id === regionId);

const isObject = obj => obj === Object(obj);

const keysToIds = obj => {
  const result = {};
  const keys = Object.keys(obj);
  const values = Object.values(obj);

  for (let i = 0; i < values.length; i++) {
    const objName = keys[i];
    const objValue = values[i];

    if (isObject(objValue)) {
      result[`${objName}_id`] = objValue.value;
    } else if (objValue !== '' && objValue !== undefined && objValue !== null) {
      result[objName] = objValue;
    }
  }

  return result;
};

const cleanObj = obj => {
  const result = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] || obj[key] === 0) result[key] = obj[key];
  });
  return result;
};

class CreateOfferPage extends React.Component {
  static propTypes = {
    references: PropTypes.shape({
      cultures: PropTypes.array,
    }).isRequired,
    cultures: PropTypes.arrayOf(PropTypes.object).isRequired,
    currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    regions: PropTypes.arrayOf(PropTypes.object).isRequired,
    elevators: PropTypes.arrayOf(PropTypes.object).isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
    classes: PropTypes.shape({
      root: PropTypes.string,
    }).isRequired,
    values: PropTypes.shape({
      info: PropTypes.string,
    }).isRequired,
    touched: PropTypes.shape({
      info: PropTypes.bool,
    }).isRequired,
    errors: PropTypes.shape({
      info: PropTypes.string,
    }).isRequired,
    submitCount: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.updateCurrencyOnRefresh();
  }

  componentDidUpdate() {
    this.updateCurrencyOnRefresh();
  }

  updateCurrencyOnRefresh = () => {
    const { currencies, values, setFieldValue } = this.props;

    if (currencies.length && values.currency === null) {
      // needed cause of some bug where Formik cannot update values on mount
      setTimeout(() => {
        setFieldValue('currency', {
          value: currencies[0].id,
          label: currencies[0].signature,
        });
      }, 1);
    }
  };

  handleSelectChange = (option, event) => {
    const { setFieldValue } = this.props;
    setFieldValue(event.name, option);

    if (event.name === 'region') {
      setFieldValue('elevator', null);
      setFieldValue('city', '');
    }
  };

  // Date picker
  handleDateChange = date => {
    const { setFieldValue } = this.props;

    const diffDate = date.diff(moment({ hours: 0 }), 'days');

    setFieldValue('expiration_time', diffDate);
  };

  openDatePicker = () => {
    this.picker.open();
  };

  render() {
    const {
      cultures,
      currencies,
      regions,
      elevators,
      values,
      submitCount,
      touched,
      errors,
      handleChange,
      handleSubmit,
      intl,
      classes,
    } = this.props;

    const {
      culture,
      info,
      currency,
      tonnage,
      price,
      region,
      elevator,
      city,
      expiration_time,
    } = values;

    const cultureOptions = cultures.map(culture => ({
      value: culture.id,
      label: culture.title,
    }));

    const currencyOptions = currencies.map(currency => ({
      value: currency.id,
      label: currency.signature,
    }));

    const regionOptions = regions.map(region => ({
      value: region.id,
      label: region.title,
    }));

    const elevatorOptions = region
      ? filterElevatorsByRegion(elevators, region.value).map(elevator => ({
          value: elevator.id,
          label: elevator.title,
        }))
      : [];

    const cost = Number(tonnage) * Number(price);

    const expirationDate = expiration_time
      ? moment().add(expiration_time, 'days')
      : '';

    const pageTitle = intl.formatMessage({ id: 'createOffer.newOffer' });
    const siteTitle = intl.formatMessage({ id: 'siteTitle' });

    return (
      <div className={`readmin-panel ${classes.root}`}>
        <Helmet>
          <title>{`${pageTitle} | ${siteTitle}`}</title>
        </Helmet>
        <div className={`panel-body ${classes.panelBody}`}>
          <div className="row justify-content-center">
            <div className="col-sm col-lg-8">
              <form onSubmit={handleSubmit}>
                <CardContent className="py-2">
                  <div className={`h1 ${classes.heading}`}>{pageTitle}</div>
                </CardContent>
                <CardContent>
                  <Autocomplete
                    options={cultureOptions}
                    isClearable
                    name="culture"
                    onChange={this.handleSelectChange}
                    value={culture}
                    placeholder={intl.formatMessage({
                      id: 'createOffer.chooseCulture',
                    })}
                    textFieldProps={{
                      label: <FormattedMessage id="createOffer.culture" />,
                      helperText:
                        (submitCount || touched.culture) && errors.culture,
                      error:
                        (submitCount || touched.culture) && !!errors.culture,
                      fullWidth: true,
                    }}
                  />
                </CardContent>
                <CardContent>
                  <TextField
                    label={<FormattedMessage id="createOffer.description" />}
                    placeholder={intl.formatMessage({
                      id: 'createOffer.enterDescription',
                    })}
                    name="info"
                    multiline
                    rows="5"
                    value={info}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                </CardContent>
                <CardContent className="pb-0">
                  <div className={classNames(classes.row8, 'row')}>
                    <div className="col-sm-3 col-3 px-2">
                      <TextField
                        type="number"
                        label={<FormattedMessage id="offers.tonnage" />}
                        placeholder={`0 ${intl.formatMessage({
                          id: 'offers.t',
                        })}`}
                        name="tonnage"
                        value={tonnage}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        fullWidth
                        margin="normal"
                        helperText={
                          (submitCount || touched.tonnage) && errors.tonnage
                        }
                        error={
                          (submitCount || touched.tonnage) && !!errors.tonnage
                        }
                      />
                    </div>
                    <div className="col-sm-3 col-5 px-2">
                      <TextField
                        type="number"
                        label={<FormattedMessage id="offers.pricePerTon" />}
                        placeholder="0"
                        name="price"
                        value={price}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            width: '125%',
                          },
                        }}
                        fullWidth
                        margin="normal"
                        helperText={
                          (submitCount || touched.price) && errors.price
                        }
                        error={(submitCount || touched.price) && !!errors.price}
                      />
                    </div>
                    <div className="col-sm-3 col-4 px-2">
                      <Autocomplete
                        options={currencyOptions}
                        name="currency"
                        onChange={this.handleSelectChange}
                        value={currency}
                        textFieldProps={{
                          label: <FormattedMessage id="createOffer.currency" />,
                          helperText:
                            (submitCount || touched.currency) &&
                            errors.currency,
                          error:
                            (submitCount || touched.currency) &&
                            !!errors.currency,
                          InputLabelProps: {
                            shrink: true,
                          },
                          margin: 'normal',
                          fullWidth: true,
                        }}
                        className={classes.textField}
                      />
                    </div>
                    <div className="col-sm-3 col-xs-5 px-2">
                      <TextField
                        label={<FormattedMessage id="createOffer.cost" />}
                        placeholder="0"
                        value={`${cost} ${currency ? currency.label : ''}`}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        fullWidth
                        margin="normal"
                      />
                    </div>
                  </div>
                </CardContent>
                <CardContent>
                  <Autocomplete
                    options={regionOptions}
                    isClearable
                    name="region"
                    onChange={this.handleSelectChange}
                    value={region}
                    placeholder={intl.formatMessage({
                      id: 'createOffer.chooseRegion',
                    })}
                    textFieldProps={{
                      label: <FormattedMessage id="createOffer.region" />,
                      helperText:
                        (submitCount || touched.region) && errors.region,
                      error: (submitCount || touched.region) && !!errors.region,
                      fullWidth: true,
                      margin: 'normal',
                    }}
                  />
                  <div className="row">
                    <div className="col-12 col-sm">
                      <Autocomplete
                        options={elevatorOptions}
                        isClearable
                        name="elevator"
                        onChange={this.handleSelectChange}
                        value={elevator}
                        placeholder={intl.formatMessage({
                          id: 'createOffer.chooseElevator',
                        })}
                        textFieldProps={{
                          label: <FormattedMessage id="createOffer.elevator" />,
                          helperText:
                            (submitCount || touched.elevator) &&
                            errors.elevator,
                          error:
                            (submitCount || touched.elevator) &&
                            !!errors.elevator,
                          fullWidth: true,
                          margin: 'normal',
                        }}
                        disabled={!region || !!city}
                      />
                    </div>
                    <div
                      className={classNames(
                        classes.verticalDivider,
                        'd-none d-sm-block',
                      )}
                    />
                    <div className="col-12 col-sm">
                      <TextField
                        label={<FormattedMessage id="createOffer.locality" />}
                        placeholder={intl.formatMessage({
                          id: 'createOffer.enterTheLocality',
                        })}
                        name="city"
                        value={city}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        disabled={!region || !!elevator}
                        helperText={
                          (submitCount || touched.city) && errors.city
                        }
                        error={(submitCount || touched.city) && !!errors.city}
                      />
                    </div>
                  </div>
                </CardContent>
                <CardContent>
                  <div className="row">
                    <div className="col-8 col-sm-3">
                      <TextField
                        label={
                          <FormattedMessage id="createOffer.expirationDate" />
                        }
                        name="expiration_time"
                        value={
                          expirationDate && expirationDate.format('DD.MM.YY')
                        }
                        onClick={this.openDatePicker}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                      <DatePicker
                        hidden
                        minDate={moment().add(1, 'days')}
                        format="d MMM YYYY"
                        value={expirationDate}
                        onChange={this.handleDateChange}
                        ref={node => {
                          this.picker = node;
                        }}
                        cancelLabel={<FormattedMessage id="forms.discard" />}
                        okLabel={<FormattedMessage id="forms.submit" />}
                        todayLabel={intl.formatMessage({
                          id: 'common.today',
                        })}
                      />
                    </div>
                  </div>
                </CardContent>
                <CardContent>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                  >
                    <FormattedMessage id="createOffer.create" />
                  </Button>
                </CardContent>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    references: getReferences(state),
    cultures: getSortedCultures(state),
    currencies: getCurrencies(state),
    regions: getRegions(state),
    elevators: getElevators(state),
  })),
  withStyles(styles),
  withSnackbar,
  injectIntl,
  withFormik({
    mapPropsToValues() {
      return {
        culture: null,
        currency: null,
        info: '',
        tonnage: '',
        price: '',
        region: null,
        elevator: null,
        city: '',
        expiration_time: 25,
      };
    },
    validationSchema: () =>
      yup.object().shape({
        culture: yup
          .object({
            value: yup.number(),
          })
          .nullable()
          .required(),

        currency: yup
          .object({
            value: yup.number(),
          })
          .nullable()
          .required(),

        info: yup.string(),

        tonnage: yup
          .number()
          .min(1)
          .max(10000)
          .required(),

        price: yup
          .number()
          .min(1)
          .max(100000)
          .required(),

        region: yup
          .object({
            value: yup.number(),
          })
          .nullable()
          .required(),

        elevator: yup.object().when(['region', 'city'], {
          is: (region, city) => !!region && !city,
          then: yup
            .object()
            .nullable()
            .required(),
          otherwise: yup.object().nullable(),
        }),

        city: yup.string().min(2),

        expiration_time: yup
          .number()
          .min(1)
          .nullable(),

        // city: yup.string().when(['region', 'elevator'], {
        //   is: (region, elevator) => !!region && !elevator,
        //   then: yup.string().required(),
        //   otherwise: yup.string(),
        // }),
      }),

    handleSubmit(values, { props, resetForm, setSubmitting }) {
      let data = keysToIds(values);
      data = cleanObj(data);

      api.offers
        .create(data)
        .then(offer => {
          setSubmitting(false);
          resetForm();

          const message = props.intl.formatMessage({
            id: 'createOffer.successCreation',
          });

          props.enqueueSnackbar(message, {
            variant: 'success',
          });

          props.history.push(`/offers/${offer.id}`);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.dir(error);
          setSubmitting(false);
          resetForm();

          let message = error && error.message;
          if (!message) {
            message = props.intl.formatMessage({
              id: 'createOffer.errorCreation',
            });
          }

          props.enqueueSnackbar(message, {
            variant: 'error',
          });
        });
      // resetForm();
    },
  }),
)(CreateOfferPage);
