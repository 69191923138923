import React from 'react';
import LayoutBanner from '../components/page-layouts/layoutBanner.js';

const PageLayoutBanner = () => (
  <div>
    <LayoutBanner />
  </div>
);

export default PageLayoutBanner;
