import React from 'react';
import Products from '../containers/products.js';

const EcommerceProductsPage = () => (
  <div>
    <Products />
  </div>
);

export default EcommerceProductsPage;
