import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import { injectIntl, FormattedMessage } from 'react-intl';
import { compose } from 'redux';

import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar from './EnhancedTableToolbar';

function desc(a, b, orderBy) {
  let aValue = a.find(row => row.id === orderBy).value;
  let bValue = b.find(row => row.id === orderBy).value;

  if (!Number.isNaN(parseFloat(aValue)) && !Number.isNaN(parseFloat(bValue))) {
    aValue = parseFloat(aValue);
    bValue = parseFloat(bValue);
  }

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  if (!array.length) return array;

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const styles = () => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const rowsPerPageOptions = [8, 16, 32];

class EnhancedTable extends React.Component {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string,
    }).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.array).isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
    title: PropTypes.element.isRequired,
    subtitle: PropTypes.element,
    actions: PropTypes.element,
    isLoading: PropTypes.bool,
    thBuilder: PropTypes.func,
    tdBuilder: PropTypes.func,
  };

  static defaultProps = {
    isLoading: false,
    subtitle: null,
    actions: null,
    thBuilder: null,
    tdBuilder: null,
  };

  state = {
    order: 'asc',
    orderBy: 'th',
    page: 0,
    rowsPerPage: rowsPerPageOptions[0],
  };

  static getDerivedStateFromProps({ columns }, { orderBy }) {
    if (columns.length <= 1) return null;

    const isPresent = columns.find(column => column.id === orderBy);

    if (isPresent) return null;

    return {
      orderBy: 'th',
    };
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  render() {
    const {
      data,
      columns,
      classes,
      intl,
      title,
      subtitle,
      actions,
      isLoading,
      thBuilder,
      tdBuilder,
    } = this.props;

    const { order, orderBy, rowsPerPage, page } = this.state;

    // const emptyRows =
    //   rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <Paper className={`${classes.root} readmin-panel`} elevation={1}>
        <EnhancedTableToolbar
          title={title}
          subtitle={subtitle}
          actions={actions}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              columns={columns}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(([th, ...columns]) => (
                  <TableRow hover tabIndex={-1} key={th.key}>
                    <TableCell component="th" scope="row">
                      {thBuilder ? (
                        thBuilder(th)
                      ) : (
                        <>
                          {th.values.map(value => (
                            <div key={value}>{value}</div>
                          ))}
                        </>
                      )}
                    </TableCell>
                    {columns.map(column => (
                      <TableCell key={column.id} align="right">
                        {tdBuilder ? (
                          tdBuilder(column)
                        ) : (
                          <>
                            {column.values.map(value => (
                              <div key={value}>{value}</div>
                            ))}
                          </>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              {(isLoading || !data.length) && (
                <TableRow
                  style={{
                    height: data.length ? 0 : 49 * rowsPerPageOptions[0],
                  }}
                >
                  <TableCell style={{ borderBottom: 'none', padding: 0 }}>
                    <div className="center-absolute">
                      {isLoading && <CircularProgress />}
                      {!data.length && !isLoading && (
                        <Typography color="textSecondary" variant="subtitle2">
                          <FormattedMessage id="common.noData" />
                        </Typography>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage={intl.formatMessage({
            id: 'common.rowsPerPage',
          })}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} ${intl.formatMessage({
              id: 'common.from',
            })} ${count}`
          }
        />
      </Paper>
    );
  }
}

export default compose(
  withStyles(styles),
  injectIntl,
)(EnhancedTable);
