import React from 'react';
import Services from '../components/pages/services.js';

const ServicePage = () => (
  <div>
    <Services />
  </div>
)

export default ServicePage;
