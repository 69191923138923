import React from 'react';
import PropTypes from 'prop-types';
import ProfileFormSmart from './editForm/ProfileFormSmart';
import api from '../../api';

class ProfilePage extends React.Component {
  static propTypes = {
    editing: PropTypes.bool,
    actionButtons: PropTypes.bool,
    customDiscardAction: PropTypes.func,
    customTitle: PropTypes.node,
    match: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    editing: false,
    actionButtons: true,
    customDiscardAction: null,
    customTitle: null,
  };

  state = {
    editing: this.props.editing,
    offerCount: {},
  };

  componentDidMount() {
    const { match } = this.props;
    const { id } = match.params;

    // If we've got user id in url
    if (id) {
      api.user.view(id).then(user => {
        this.setState({ offerCount: user.offer_count });
      });
    }
  }

  toggleEdit = (input, formikBag) => {
    this.setState(
      prevState => ({ editing: !prevState.editing }),
      () => {
        if (this.state.editing) {
          if (input) {
            // eslint-disable-next-line no-multi-assign, no-param-reassign
            input.selectionStart = input.selectionEnd = input.value.length; // places carret at the end of text
            input.focus();
          }
        } else if (formikBag) {
          formikBag.resetForm();
        }
      },
    );
  };

  render() {
    const {
      actionButtons,
      customDiscardAction,
      customTitle,
      match,
    } = this.props;

    const { editing, offerCount } = this.state;

    return (
      <ProfileFormSmart
        editing={editing}
        toggleEdit={this.toggleEdit}
        actionButtons={actionButtons}
        customDiscardAction={customDiscardAction}
        customTitle={customTitle}
        match={match}
        offerCount={offerCount}
      />
    );
  }
}

export default ProfilePage;
