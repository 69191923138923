import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import PanelCentred from './PanelCentred';

export default function Loader(props) {
  return (
    <PanelCentred {...props}>
      <CircularProgress />
    </PanelCentred>
  );
}
