import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const Page404 = () => (
  <div className="login-wrapper">
    <div className="login-fields text-center">
      <h3 className="title-404">404</h3>
      <div className="pt20">
        <p>
          <FormattedMessage id="404.description" />
        </p>
      </div>
      <div className="pt20">
        <Link to="/">
          <Button color="primary" variant="contained" fullWidth>
            <FormattedMessage id="404.button" />
          </Button>
        </Link>
      </div>
    </div>
  </div>
);

export default Page404;
