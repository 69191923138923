import React from 'react';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import OfferPage from './dumb/OfferPage';
import api from '../../api';

class OfferViewPage extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      id: PropTypes.number,
      user_type: PropTypes.string,
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.object,
    }).isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  state = {
    offer: {},
    isLoading: false,
    isRequesting: false,
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    this.setState({ isLoading: true });

    api.offers.view(id).then(offer => {
      this.setState({ offer, isLoading: false });
    });
  }

  handleOfferChange = offer => {
    this.setState(prevState => ({
      isRequesting: false,
      offer: {
        ...prevState.offer,
        ...offer,
      },
    }));
  };

  handleOfferPatch = params => {
    const { id } = this.state.offer;
    this.setState({ isRequesting: true });

    return api.offers.patch(id, params).then(offer => {
      this.handleOfferChange(offer);
      return offer;
    });
  };

  onBuy = () => {
    this.handleOfferPatch({ offer_state: 'bought' });
  };

  onSell = () => {
    this.handleOfferPatch({ offer_state: 'sold' });
  };

  onDiscard = () => {
    this.handleOfferPatch({ offer_state: 'active' });
  };

  onDeliveryStarted = () => {
    this.handleOfferPatch({ offer_state: 'delivery' });
  };

  onCancel = () => {
    this.handleOfferPatch({ offer_state: 'cancelled' }).then(offer => {
      const { intl, enqueueSnackbar } = this.props;

      this.props.history.push('/');
      const message = intl.formatMessage(
        {
          id: 'offers.offerIsClosed',
        },
        {
          id: offer.id,
        },
      );

      enqueueSnackbar(message, {
        variant: 'error',
      });
    });
  };

  onDelivery = delivered => () => {
    this.handleOfferPatch({ delivered });
  };

  onDeliveryConfirmed = confirmed => () => {
    this.handleOfferPatch({ confirmed });
  };

  onBuyerCancel = () => {
    this.handleOfferPatch({ buyer_cancel_request: true });
  };

  onSellerCancel = () => {
    this.handleOfferPatch({ seller_cancel_request: true });
  };

  onBuyerRestore = () => {
    this.handleOfferPatch({ buyer_cancel_request: false });
  };

  onSellerRestore = () => {
    this.handleOfferPatch({ seller_cancel_request: false });
  };

  onExpiresChange = date => {
    const expiresAt = date.format('YYYY-MM-DD HH:mm:ss');
    this.handleOfferPatch({ expires_at: expiresAt }).then(() => {
      const { intl, enqueueSnackbar } = this.props;

      const message = intl.formatMessage({
        id: 'offers.offerExtended',
      });

      enqueueSnackbar(message, {
        variant: 'success',
      });
    });
  };

  render() {
    const { user, history, location } = this.props;
    const { offer, isLoading, isRequesting } = this.state;

    const {
      onBuy,
      onSell,
      onDiscard,
      onDeliveryStarted,
      onCancel,
      onDelivery,
      onDeliveryConfirmed,
      onBuyerCancel,
      onSellerCancel,
      onBuyerRestore,
      onSellerRestore,
      onExpiresChange,
    } = this;

    const actionHandlers = {
      onBuy,
      onSell,
      onDiscard,
      onDeliveryStarted,
      onCancel,
      onDelivery,
      onDeliveryConfirmed,
      onBuyerCancel,
      onSellerCancel,
      onBuyerRestore,
      onSellerRestore,
      onExpiresChange,
    };

    return (
      <OfferPage
        offer={offer}
        user={user}
        isLoading={isLoading}
        isRequesting={isRequesting}
        actionHandlers={actionHandlers}
        history={history}
        location={location}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

export default compose(
  withSnackbar,
  injectIntl,
  connect(mapStateToProps),
)(OfferViewPage);
