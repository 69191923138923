import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import ProfilePage from './ProfilePage';
import AxiosInterceptors from '../../api/AxiosInterceptors';
import { logout } from '../../store/actions';

const ActivatePage = ({ logout, match }) => (
  <div className="activate-page">
    <div className="container">
      <AxiosInterceptors />
      <ProfilePage
        editing
        customDiscardAction={logout}
        customTitle={<FormattedMessage id="profile.userActivation" />}
        actionButtons={false}
        match={match}
      />
    </div>
  </div>
);

ActivatePage.propTypes = {
  logout: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,
};

export default connect(
  null,
  {
    logout,
  },
)(ActivatePage);
