import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { fetchOffers, offersDrop } from '../../store/actions';
import OffersLayout from './dumb/OffersLayout';
// import Fab from '../ui/Fab';

class OffersListPage extends React.Component {
  static propTypes = {
    offers: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    fetchOffers: PropTypes.func.isRequired,
    offersDrop: PropTypes.func.isRequired,
    userType: PropTypes.string.isRequired,

    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    isLoading: false,
  };

  componentDidMount() {
    const { fetchOffers } = this.props;

    fetchOffers();
  }

  componentWillUnmount() {
    const { offersDrop } = this.props;

    offersDrop();
  }

  render() {
    const { offers, isLoading, userType, intl } = this.props;

    const createOfferText = intl.formatMessage({ id: 'offers.createOffer' });
    const buttons = userType === 'seller' && (
      <Link to="/create-offer">
        <Fab variant="extended" color="primary" aria-label={createOfferText}>
          <AddIcon />
          <span className="ml-2 d-none d-sm-inline">{createOfferText}</span>
        </Fab>
      </Link>
    );

    const title = intl.formatMessage({ id: 'nav.offers' });
    const siteTitle = intl.formatMessage({ id: 'siteTitle' });

    return (
      <React.Fragment>
        <Helmet>
          <title>{`${title} | ${siteTitle}`}</title>
        </Helmet>
        <OffersLayout offers={offers} isLoading={isLoading} buttons={buttons} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userType: state.user.user_type,
  offers: state.offers.data,
  isLoading: state.offers.loading,
});

const mapDispatchToProps = {
  fetchOffers,
  offersDrop,
};

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(OffersListPage);
