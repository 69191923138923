export const enUS = {
  siteTitle: 'Agrofair',

  common: {
    from: 'from',
    sort: 'Sort',
    today: 'Today',
    rowsPerPage: 'Rows per page',
    noData: 'No data',
    noResults: 'No results',
    error: 'Error',
    showMore: 'Show more',
    or: 'or',
    goBack: 'Go back',
    select: 'Select',
  },

  forms: {
    fullname: 'Full name',
    fullnameHint: 'John Doe',
    phone: 'Phone number',
    submit: 'Submit',
    discard: 'Discard',
    save: 'Save',
    password: 'Password',
    changesSaved: 'Changes saved',
    error403: 'No access, most likely your account is blocked.',
  },

  nav: {
    offers: 'Offers',
    profile: 'Profile',
    messages: 'Messages',
    chats: 'Chats',
    settings: 'Settings',
    priceList: 'Price list',
    exchange: 'Stock Exchange',
  },

  auth: {
    login: {
      heading: 'Login',
      forgotPassword: 'Forgot your password?',
      enter: 'Enter',
      rememberMe: 'Remember me',
      logout: 'Logout',
    },
    forgotPassword: {
      enterYourEmail: 'Enter your email',
    },
  },

  profile: {
    activeOffers: 'Active offers',
    archivedOffers: 'Expired offers',
    userActivation: 'User activation',
    changePassword: 'Change Password',
    currentPassword: 'Current password',
    currentPasswordHint: 'Enter the current password',
    newPassword: 'New password',
    newPasswordHint: 'Enter a new password',
    confirmPassword: 'Password confirmation',
    confirmPasswordHint: 'Enter new password again',
  },

  settings: {
    notifications: {
      generalChat: 'General chat notifications',
    },
  },

  prices: {
    subtitle: 'dated',
    pickDate: 'Pick date',
    pickCurrency: 'Pick currency',
    allCurrencies: 'All currencies',
    showToday: 'Show today',
  },

  offers: {
    t: 't',
    bought: 'Bought',
    sold: 'Sold',
    cancelled: 'Cancelled',
    delivery: 'Delivery',
    tonnage: 'Tonnage',
    pricePerTon: 'Price per ton',
    totalСost: 'Total cost',
    region: 'Region',
    elevator: 'Elevator',
    city: 'City',
    buy: 'Buy',
    writeToSeller: 'Write to seller',
    writeToBuyer: 'Write to buyer',
    confirmOffer: 'Confirm offer',
    cancelOffer: 'Cancel offer',
    cancelPurchase: 'Cancel purchase',
    restoreOffer: 'Restore offer',
    declineOffer: 'Decline offer',
    confirmСancellation: 'Confirm cancellation',
    offerExpires: 'Offer expires',
    boughtYourOffer: 'bought your offer',
    youHaveBookedAnOffer: 'You have booked an offer until',
    youHaveCancelledAnOffer: 'You have cancelled an offer',
    buyerHaveCancelledAnOffer: 'Buyer have cancelled an offer',
    sellerHaveCancelledAnOffer: 'Seller have cancelled an offer',
    expectedDelivery: 'Expected delivery of {tonnage} t.',
    ofDelivered: '{confirmed} out of {tonnage} t. delivered',
    waitingDeliveryConfirmation: 'Waiting for delivery confirmation',
    confirmDelivery: 'Confirm delivery {delivered} t.',
    confirmFullDelivery: 'Confirm full delivery',
    allGoodsDelivered: 'All goods delivered',
    goodsAmount: 'Quantity of goods',
    addAll: 'Add all',
    deliverGoods: 'Deliver the goods',
    dealCompleted: 'Deal completed',
    createOffer: 'Create offer',
    extendTheOffer: 'Extend the term of the offer',
    offerExtended: 'Offer successfully extended',
    closeOffer: 'Close an offer',
    offerIsClosed: 'Offer {id} is closed',
    noOffersYet: 'No offers yet',
  },

  createOffer: {
    newOffer: 'New offer',
    culture: 'Culture',
    chooseCulture: 'Choose culture',
    description: 'Description',
    enterDescription: 'Enter description',
    currency: 'Сurrency',
    cost: 'Cost',
    region: 'Region',
    chooseRegion: 'Choose region',
    elevator: 'Elevator',
    chooseElevator: 'Сhoose elevator',
    locality: 'Дocality',
    enterTheLocality: 'Enter the locality',
    expirationDate: 'Expiration date',
    create: 'Create',
    successCreation: 'Offer successfully created',
    errorCreation: 'Error creating offer',
  },

  exchange: {
    exchangeRates: 'Exchange Rates',
    quotes: 'Quotes',
    updated: 'Updated',
  },

  '404': {
    description: 'The page you are looking for was not found.',
    button: 'Go back to home',
  },
};
