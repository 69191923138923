import React from 'react';
import { Helmet } from 'react-helmet';

import './static/css/App.css';
import './static/css/vendor-styles.css';

import AuthRouter from './components/routing/AuthRouter';
import { config } from './config';

const App = () => (
  <>
    <Helmet>
      <meta name="google-play-app" content={`app-id=${config.googlePlayId}`} />
      <meta name="apple-itunes-app" content={`app-id=${config.appStoreId}`} />
    </Helmet>
    <AuthRouter />
  </>
);

export default App;
