import React from 'react';
import Header from './Header';
import AppLayout from './AppLayout';
import AxiosInterceptors from '../../api/AxiosInterceptors';

import { withReferences } from '../../hocs';

const Authorized = () => (
  <React.Fragment>
    <AxiosInterceptors />
    <Header />
    <AppLayout />
  </React.Fragment>
);

export default withReferences(Authorized);
