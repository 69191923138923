import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_ACTIVATED,
  USER_LOADED,
  USER_EDITED_PROFILE,
  USER_UPLOADED_AVATAR,
} from '../types';

const initState = { loaded: false };

export default function user(state = initState, action = {}) {
  switch (action.type) {
    case USER_LOGGED_IN:
      return {
        ...action.user,
        loaded: true,
      };

    case USER_LOGGED_OUT:
      return {
        loaded: true,
      };

    case USER_ACTIVATED:
      return {
        ...state,
        ...action.user,
        loaded: true,
      };

    case USER_LOADED:
      return {
        ...state,
        loaded: true,
      };

    case USER_EDITED_PROFILE:
      return {
        ...state,
        ...action.user,
      };

    case USER_UPLOADED_AVATAR:
      return {
        ...state,
        avatar: action.url,
      };

    default:
      return state;
  }
}
