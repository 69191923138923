import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';

import Logo from './Logo';
import LoginForm from './LoginForm';
import { login } from '../../store/actions';

class LoginPage extends React.Component {
  submit = base64string => {
    const { login, history, location } = this.props;

    return login(base64string).then(() => {
      let goTo = '/offers';

      if (location.state && location.state.from) {
        goTo = location.state.from;
      }

      history.push(goTo);
    });
  };

  render() {
    const { intl } = this.props;
    const title = intl.formatMessage({ id: 'auth.login.heading' });
    const siteTitle = intl.formatMessage({ id: 'siteTitle' });

    return (
      <div>
        <Helmet>
          <title>{`${title} | ${siteTitle}`}</title>
        </Helmet>
        <Logo />
        <LoginForm submit={this.submit} />
      </div>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  login: PropTypes.func.isRequired,
};

export default compose(
  injectIntl,
  connect(
    null,
    { login },
  ),
)(LoginPage);
