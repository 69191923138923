import React from 'react';
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from 'react-intl';

const ForgotPasswordForm = () => (
  <form name="login-form" className="login-form">
    <div className="login-wrapper">
      <div className="login-fields">
        <h3>
          <FormattedMessage id="auth.forgotPassword.enterYourEmail" />
          <Link to="/login">
            <FormattedMessage id="auth.login.enter" />
          </Link>
        </h3>
        <TextField
          id="email"
          name="email"
          type="email"
          fullWidth
          label="E-mail"
          placeholder="example@domain.com"
          margin="normal"
          // disabled={isSubmitting}
          // onChange={handleChange}
          // value={values.email}
          // helperText={(submitCount || touched.email) && errors.email}
          // error={(submitCount || touched.email) && errors.email}
        />
        <div className="pt20">
          <Button color="primary" variant="contained" fullWidth>
            <FormattedMessage id="forms.submit" />
          </Button>
        </div>
      </div>
    </div>
  </form>
);

export default ForgotPasswordForm;
