import React from 'react';
import Register from '../components/other-pages/register.js';

const RegisterPage = () => (
  <div>
    <Register />
  </div>

)

export default RegisterPage;
