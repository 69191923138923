import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import ProfileForm from './editForm/ProfileForm';
import api from '../../api';

class UserPage extends Component {
  static propTypes = {
    match: PropTypes.shape({
      url: PropTypes.string,
      params: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  state = {
    isLoading: false,
    user: {},
  };

  componentDidMount() {
    const id = Number(this.props.match.params.id);

    this.setState({ isLoading: true });

    api.user.view(id).then(user => {
      this.setState({ user, isLoading: false });
    });
  }

  render() {
    const { match } = this.props;
    const { isLoading, user } = this.state;

    return (
      <React.Fragment>
        {isLoading && (
          <div className="center-fixed">
            <CircularProgress />
          </div>
        )}
        <ProfileForm
          actionButtons={false}
          readOnly
          user={user}
          offerCount={user.offer_count}
          match={match}
        />
      </React.Fragment>
    );
  }
}

export default UserPage;
