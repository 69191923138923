import React from 'react';
import AvatarDropdown from './AvatarDropdown';
// import Notification from './notification';

const Header = () => (
  <header className="an-header mui-fixed">
    <div className="header-right">
      {/* <Notification /> */}
      <AvatarDropdown />
    </div>
  </header>
);

export default Header;
