import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import ActionAndroid from 'material-ui/svg-icons/action/android';
import FontIcon from 'material-ui/FontIcon';

import Panel from '../panel.js';

const styles = {
  button: {
    margin: 12,
  },
  exampleImageInput: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    width: '100%',
    opacity: 0,
  },
};

const RaisedComplexButtons = () => (
  <Panel title="Raised Comple Buttons">
    <div>
      <RaisedButton
        label="Choose an Image"
        labelPosition="before"
        style={styles.button}
        containerElement="label"
      >
        <input type="file" style={styles.exampleImageInput} />
      </RaisedButton>
      <RaisedButton
        label="Label before"
        labelPosition="before"
        primary={true}
        icon={<ActionAndroid />}
        style={styles.button}
      />
      <RaisedButton
        href="https://github.com/callemall/material-ui"
        target="_blank"
        label="Github Link"
        secondary={true}
        style={styles.button}
        icon={<FontIcon className="muidocs-icon-custom-github" />}
      />
      </div>
    </Panel>
);

export default RaisedComplexButtons;
