import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
// import SettingsIcon from '@material-ui/icons/Settings';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import InputMask from 'react-input-mask';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import ImagePicker from './ImagePicker';

class ProfileEditForm extends React.Component {
  static propTypes = {
    user: PropTypes.shape({
      authdata: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      email: PropTypes.string,
      state: PropTypes.string,
      phone: PropTypes.string,
    }).isRequired,
    readOnly: PropTypes.bool,
    offerCount: PropTypes.shape({
      active: PropTypes.number,
      archived: PropTypes.number,
    }),
    editing: PropTypes.bool,
    actionButtons: PropTypes.bool,
    customDiscardAction: PropTypes.func,
    customTitle: PropTypes.node,
    toggleEdit: PropTypes.func,
    resetForm: PropTypes.func,

    /* following props are used in formik, but eslint cannot see them */
    /* eslint-disable react/no-unused-prop-types */
    activateProfile: PropTypes.func,
    editProfile: PropTypes.func,
    uploadAvatar: PropTypes.func,
    enqueueSnackbar: PropTypes.func,
    /* eslint-enable react/no-unused-prop-types */

    values: PropTypes.shape({
      avatar: PropTypes.instanceOf(Blob),
      name: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.string,
      currentPassword: PropTypes.string,
      password: PropTypes.string,
      confirmPassword: PropTypes.string,
    }),
    errors: PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      email: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      phone: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      currentPassword: PropTypes.string,
      password: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      confirmPassword: PropTypes.string,
    }),
    touched: PropTypes.shape({
      name: PropTypes.bool,
      email: PropTypes.bool,
      phone: PropTypes.bool,
      currentPassword: PropTypes.bool,
      password: PropTypes.bool,
      confirmPassword: PropTypes.bool,
    }),

    isSubmitting: PropTypes.bool,
    submitCount: PropTypes.number,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func,
    setFieldValue: PropTypes.func,

    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,

    match: PropTypes.shape({
      params: PropTypes.object,
      url: PropTypes.string,
    }).isRequired,
  };

  static defaultProps = {
    readOnly: false,
    offerCount: {},
    editing: false,
    actionButtons: true,
    customDiscardAction: null,
    customTitle: null,
    toggleEdit: null,
    resetForm: null,
    activateProfile: null,
    editProfile: null,
    uploadAvatar: null,
    enqueueSnackbar: null,
    values: {
      name: '',
      email: '',
      phone: '',
    },
    errors: {},
    touched: {},
    isSubmitting: false,
    submitCount: 0,
    handleChange: null,
    handleSubmit: null,
    setFieldValue: null,
  };

  nameInput = null;

  setNameInputRef = element => {
    this.nameInput = element;
  };

  handleToggleEdit = () => {
    const { toggleEdit, resetForm } = this.props;

    toggleEdit(this.nameInput, { resetForm });
  };

  handleAvatarUpload = image => {
    const { setFieldValue } = this.props;

    setFieldValue('avatar', image);
  };

  render() {
    const {
      user,
      readOnly,
      offerCount,
      editing,
      actionButtons,
      customDiscardAction,
      customTitle,

      errors,
      touched,
      isSubmitting,
      submitCount,
      handleChange,
      handleSubmit,

      intl,
      match,
    } = this.props;

    let { values } = this.props;
    if (readOnly && user.name) {
      const { avatar, ...userWithoutAvatar } = user;
      values = userWithoutAvatar;
    }

    const {
      avatar: avatarBlob,
      name,
      email,
      phone,
      currentPassword,
      password,
      confirmPassword,
    } = values;

    // const avatarBlob = values.avatar instanceof Blob ? values.avatar : null;

    const title = user.name || intl.formatMessage({ id: 'nav.profile' });
    const siteTitle = intl.formatMessage({ id: 'siteTitle' });

    return (
      <div className="readmin-panel profile-panel">
        <Helmet>
          <title>{`${title} | ${siteTitle}`}</title>
        </Helmet>
        {actionButtons && (
          <div className="panel-heading">
            <div
              className="panel-heading-buttons"
              style={{ marginLeft: 'auto' }}
            >
              <IconButton
                onClick={this.handleToggleEdit}
                color={editing ? 'primary' : 'default'}
              >
                <EditIcon />
              </IconButton>
              {/* <Link to="/settings">
                <IconButton>
                  <SettingsIcon />
                </IconButton>
              </Link> */}
            </div>
          </div>
        )}
        <div className="panel-body">
          <div className="row">
            <div className="col-md-4">
              <div className="profile-image">
                <ImagePicker
                  avatar={user.avatar}
                  avatarBlob={avatarBlob}
                  editing={editing}
                  onSave={this.handleAvatarUpload}
                />
              </div>
            </div>

            <div className="col-md-8">
              <form
                name="profile-edit-form"
                className="profile-edit-form"
                onSubmit={handleSubmit}
              >
                <div className="h1 profile-heading">
                  {customTitle || user.name}
                </div>
                <div>
                  <TextField
                    id="name"
                    value={name}
                    name="name"
                    margin="normal"
                    onChange={handleChange}
                    label={<FormattedMessage id="forms.fullname" />}
                    placeholder={intl.formatMessage({
                      id: 'forms.fullnameHint',
                    })}
                    helperText={(submitCount || touched.name) && errors.name}
                    error={(submitCount || touched.name) && !!errors.name}
                    InputProps={{
                      readOnly: !editing,
                      disableUnderline: !editing,
                      inputProps: {
                        maxLength: 50,
                      },
                    }}
                    inputRef={this.setNameInputRef}
                  />
                </div>
                <div>
                  <TextField
                    id="email"
                    name="email"
                    type="email"
                    value={email}
                    margin="normal"
                    label="E-mail"
                    placeholder="example@domain.com"
                    onChange={handleChange}
                    helperText={(submitCount || touched.email) && errors.email}
                    error={(submitCount || touched.email) && !!errors.email}
                    InputProps={{
                      readOnly: !editing,
                      disableUnderline: !editing,
                      inputProps: {
                        maxLength: 254,
                      },
                    }}
                  />
                </div>
                {(phone || editing) && (
                  <div>
                    <InputMask
                      mask="+38 099 999 99 99"
                      maskChar=""
                      value={phone}
                      onChange={handleChange}
                    >
                      {() => (
                        <TextField
                          id="phone"
                          name="phone"
                          type="tel"
                          margin="normal"
                          label={<FormattedMessage id="forms.phone" />}
                          placeholder="+38 (077) 777-77-77"
                          helperText={
                            (submitCount || touched.phone) && errors.phone
                          }
                          error={
                            (submitCount || touched.phone) && !!errors.phone
                          }
                          InputProps={{
                            readOnly: !editing,
                            disableUnderline: !editing,
                            inputProps: {
                              maxLength: 50,
                            },
                          }}
                        />
                      )}
                    </InputMask>
                  </div>
                )}
                <Collapse in={!editing}>
                  <div className="pt30">
                    <Typography variant="h6">
                      <FormattedMessage id="nav.offers" />
                    </Typography>
                    <div className="my-2">
                      <Link to={`${match.url}/offers/active`}>
                        <FormattedMessage id="profile.activeOffers" />
                        <Typography
                          color="textSecondary"
                          component="span"
                          inline
                        >
                          {` `}
                          {offerCount.active}
                        </Typography>
                      </Link>
                    </div>
                    <div className="my-2">
                      <Link to={`${match.url}/offers/archive`}>
                        <FormattedMessage id="profile.archivedOffers" />
                        <Typography
                          color="textSecondary"
                          component="span"
                          inline
                        >
                          {` `}
                          {offerCount.archived}
                        </Typography>
                      </Link>
                    </div>
                  </div>
                </Collapse>
                <Collapse in={editing}>
                  <div className="pt30">
                    <Typography variant="h6">
                      <FormattedMessage id="profile.changePassword" />
                    </Typography>
                    <div>
                      <TextField
                        id="currentPassword"
                        value={currentPassword}
                        name="currentPassword"
                        margin="normal"
                        onChange={handleChange}
                        label={
                          <FormattedMessage id="profile.currentPassword" />
                        }
                        placeholder={intl.formatMessage({
                          id: 'profile.currentPasswordHint',
                        })}
                        helperText={
                          (submitCount || touched.currentPassword) &&
                          errors.currentPassword
                        }
                        error={
                          (submitCount || touched.currentPassword) &&
                          !!errors.currentPassword
                        }
                        InputProps={{
                          readOnly: !editing,
                          disableUnderline: !editing,
                          inputProps: {
                            maxLength: 128,
                          },
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        id="password"
                        value={password}
                        name="password"
                        margin="normal"
                        onChange={handleChange}
                        label={<FormattedMessage id="profile.newPassword" />}
                        placeholder={intl.formatMessage({
                          id: 'profile.newPasswordHint',
                        })}
                        helperText={
                          (submitCount || touched.password) && errors.password
                        }
                        error={
                          (submitCount || touched.password) && !!errors.password
                        }
                        InputProps={{
                          readOnly: !editing,
                          disableUnderline: !editing,
                          inputProps: {
                            maxLength: 128,
                          },
                        }}
                      />
                    </div>
                    <div>
                      <TextField
                        id="confirmPassword"
                        value={confirmPassword}
                        name="confirmPassword"
                        margin="normal"
                        onChange={handleChange}
                        label={
                          <FormattedMessage id="profile.confirmPassword" />
                        }
                        placeholder={intl.formatMessage({
                          id: 'profile.confirmPasswordHint',
                        })}
                        helperText={
                          (submitCount || touched.confirmPassword) &&
                          errors.confirmPassword
                        }
                        error={
                          (submitCount || touched.confirmPassword) &&
                          !!errors.confirmPassword
                        }
                        InputProps={{
                          readOnly: !editing,
                          disableUnderline: !editing,
                          inputProps: {
                            maxLength: 128,
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="pt30">
                    <Button
                      disabled={isSubmitting}
                      color="primary"
                      type="submit"
                      variant="contained"
                      size="large"
                      className="mb-3 mr-4 "
                    >
                      {isSubmitting ? (
                        <CircularProgress thickness={5} size={24} />
                      ) : (
                        <FormattedMessage id="forms.save" />
                      )}
                    </Button>
                    <Button
                      onClick={customDiscardAction || this.handleToggleEdit}
                      disabled={isSubmitting}
                      type="button"
                      className="mb-3"
                      size="large"
                    >
                      <FormattedMessage id="forms.discard" />
                    </Button>
                  </div>
                </Collapse>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(ProfileEditForm);
