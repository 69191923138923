import green from '@material-ui/core/colors/green';
import lime from '@material-ui/core/colors/lime';
import lightBlue from '@material-ui/core/colors/lightBlue';
import purple from '@material-ui/core/colors/purple';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import orange from '@material-ui/core/colors/orange';
import brown from '@material-ui/core/colors/brown';

const colors = [
  green,
  lime,
  lightBlue,
  indigo,
  purple,
  pink,
  red,
  orange,
  yellow,
  brown,
];

export const getColorFromIndex = index => {
  let colorIndex = index || 1;
  if (colorIndex > 9) colorIndex = colorIndex.toString().slice(-1);
  return colors[colorIndex];
};
