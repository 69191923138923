import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage, FormattedDate, injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import UpdateIcon from '@material-ui/icons/Update';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Button from '@material-ui/core/Button';
import { DatePicker } from 'material-ui-pickers';

import { fetchPrices, pricesFilter } from '../../store/actions';

import {
  getFilteredPrices,
  getPricesCultures,
  getPricesElevators,
  getPricesCurrencies,
  isRefsLoaded,
} from '../../store/selectors';

import EnhancedTable from '../common/tables/EnhancedTable';

const thBuilder = th => {
  const { values } = th;

  return (
    <>
      <div>{values[0]}</div>
      <div className="text-black-50">{values[1]}</div>
    </>
  );
};

const tdBuilder = td => {
  const { values } = td;

  return (
    <>
      {values.map(value => (
        <div key={value} className="py-1">
          <nobr>{value}</nobr>
        </div>
      ))}
    </>
  );
};

class PricesPage extends React.Component {
  static propTypes = {
    prices: PropTypes.arrayOf(PropTypes.object).isRequired,
    cultures: PropTypes.arrayOf(PropTypes.object).isRequired,
    elevators: PropTypes.arrayOf(PropTypes.object).isRequired,
    currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    pricesDate: PropTypes.string,
    todayClosestDate: PropTypes.string,
    fetchPrices: PropTypes.func.isRequired,
    pricesFilter: PropTypes.func.isRequired,
    selectedCurrency: PropTypes.shape({
      id: PropTypes.number,
      signature: PropTypes.string,
    }),
    isLoading: PropTypes.bool,

    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    pricesDate: '',
    todayClosestDate: '',
    isLoading: false,
    selectedCurrency: null,
  };

  state = {
    selectedDate: Date.now(),
    currencyEl: null,
  };

  componentDidMount() {
    const { fetchPrices, prices } = this.props;

    if (!prices.length) {
      fetchPrices();
    }
  }

  componentDidUpdate() {
    const { prices, pricesFilter, selectedCurrency } = this.props;

    if (!prices.length && selectedCurrency) {
      pricesFilter({
        currency: null,
      });
    }
  }

  generateColumns = cultures => {
    const { intl } = this.props;
    const columns = [];

    cultures.forEach(culture => {
      columns.push({
        id: culture.id,
        label: culture.title,
        numeric: true,
      });
    });

    columns.sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();

      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;

      return 0;
    });

    columns.unshift({
      id: 'th',
      label: intl.formatMessage({ id: 'offers.elevator' }),
      numeric: false,
    });

    return columns;
  };

  generateTableData = ({ prices, elevators, columns }) => {
    const tableData = [];

    elevators.forEach(elevator => {
      const tableRow = [
        {
          id: 'th',
          key: elevator.id,
          value: `${elevator.title}, ${elevator.region}`,
          values: [`${elevator.title}`, `${elevator.region}`],
        },
      ];

      columns.forEach(column => {
        if (column.id !== 'th') {
          tableRow.push({
            id: column.id,
            label: column.label,
            value: '',
            text: '',
            values: [],
          });
        }
      });

      prices.forEach(price => {
        if (price.elevator.id === elevator.id) {
          const row = tableRow.find(row => row.id === price.culture.id);

          if (row) {
            row.text += `${price.value} ${price.currency.signature} \n`;
            row.value = price.value;

            row.values.push(`${price.value} ${price.currency.signature}`);
          }
        }
      });

      tableData.push(tableRow);
    });

    return tableData;
  };

  handleDateChange = date => {
    const { fetchPrices } = this.props;
    const dateString =
      typeof date === 'string' ? date : date.format('YYYY-MM-DD');

    fetchPrices(dateString);
    this.setState({ selectedDate: dateString });
  };

  handleDateReset = () => {
    const { todayClosestDate } = this.props;

    this.handleDateChange(todayClosestDate);
  };

  openDatePicker = e => {
    this.picker.open(e);
  };

  handleCurrencyOpen = event => {
    this.setState({ currencyEl: event.currentTarget });
  };

  handleCurrencyClick = event => {
    const { signature, id } = event.currentTarget.dataset;
    this.props.pricesFilter({ currency: { id: +id, signature } });
    this.handleCurrencyClose();
  };

  handleCurrencyReset = () => {
    this.props.pricesFilter({ currency: {} });
    this.handleCurrencyClose();
  };

  handleCurrencyClose = () => {
    this.setState({ currencyEl: null });
  };

  render() {
    const { selectedDate, currencyEl } = this.state;
    const {
      prices,
      cultures,
      elevators,
      currencies,
      pricesDate,
      todayClosestDate,
      selectedCurrency,
      isLoading,
      intl,
    } = this.props;

    const isCurrentDate = pricesDate === todayClosestDate;

    const currencyOpen = Boolean(currencyEl);

    const tableColumns = this.generateColumns(cultures);
    const tableData = this.generateTableData({
      prices,
      elevators,
      columns: tableColumns,
    });

    const title = intl.formatMessage({ id: 'nav.priceList' });
    const siteTitle = intl.formatMessage({ id: 'siteTitle' });

    const Title = <FormattedMessage id="nav.priceList" />;

    const Subtitle = pricesDate ? (
      <React.Fragment>
        <span className="mr-4">
          <FormattedMessage id="prices.subtitle" />
          {` `}
          <FormattedDate
            value={pricesDate}
            year="numeric"
            month="long"
            day="2-digit"
          />
        </span>

        {!isCurrentDate && (
          <Button
            color="secondary"
            size="small"
            onClick={this.handleDateReset}
            style={{ marginLeft: '-8px' }}
          >
            <FormattedMessage id="prices.showToday" />
          </Button>
        )}
      </React.Fragment>
    ) : null;

    const Actions = (
      <React.Fragment>
        <Tooltip
          title={<FormattedMessage id="prices.pickCurrency" />}
          disableFocusListener
        >
          <IconButton
            aria-label="More"
            aria-owns={currencyOpen ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={this.handleCurrencyOpen}
          >
            <MonetizationOnIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          title={<FormattedMessage id="prices.pickDate" />}
          disableFocusListener
        >
          <IconButton onClick={this.openDatePicker}>
            <UpdateIcon />
          </IconButton>
        </Tooltip>

        <Menu
          id="long-menu"
          anchorEl={currencyEl}
          getContentAnchorEl={null}
          open={currencyOpen}
          onClose={this.handleCurrencyClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <MenuItem
            selected={!selectedCurrency}
            onClick={this.handleCurrencyReset}
          >
            <FormattedMessage id="prices.allCurrencies" />
          </MenuItem>
          {!!currencies.length &&
            currencies.map(option => (
              <MenuItem
                key={option.id}
                selected={
                  selectedCurrency &&
                  option.signature === selectedCurrency.signature
                }
                onClick={this.handleCurrencyClick}
                data-id={option.id}
                data-signature={option.signature}
                disabled={currencies.length === 1}
              >
                {option.title}
              </MenuItem>
            ))}
        </Menu>

        <DatePicker
          hidden
          disableFuture
          showTodayButton
          format="d MMM YYYY"
          value={selectedDate}
          onChange={this.handleDateChange}
          ref={node => {
            this.picker = node;
          }}
          cancelLabel={<FormattedMessage id="forms.discard" />}
          okLabel={<FormattedMessage id="forms.submit" />}
          todayLabel={intl.formatMessage({
            id: 'common.today',
          })}
        />
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>{`${title} | ${siteTitle}`}</title>
        </Helmet>
        <EnhancedTable
          data={tableData}
          columns={tableColumns}
          title={Title}
          subtitle={Subtitle}
          actions={Actions}
          isLoading={isLoading && !tableData.length}
          thBuilder={thBuilder}
          tdBuilder={tdBuilder}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  prices: getFilteredPrices(state),
  cultures: getPricesCultures(state),
  elevators: getPricesElevators(state),
  currencies: getPricesCurrencies(state),
  isRefsLoaded: isRefsLoaded(state),
  pricesDate: state.prices.date,
  todayClosestDate: state.prices.todayClosestDate,
  selectedCurrency: state.prices.filters.currency,
  isLoading: state.prices.loading,
});

const mapDispatchToProps = {
  fetchPrices,
  pricesFilter,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  injectIntl,
)(PricesPage);
