export default function () {
  return [
    {
      id: 1,
      name: 'John Smith',
      status: 'Employed'
    },
    {
      id: 2,
      name: 'Randal White',
      status: 'Unemployed'
    },
    {
      id: 3,
      name: 'Stephanie Sanders',
      status: 'Employed'
    },
    {
      id: 4,
      name: 'Steve Brown',
      status: 'Employed'
    },
    {
      id: 5,
      name: 'Christopher Nolano',
      status: 'Unemployed'
    }

  ]
}
