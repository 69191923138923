import axios from 'axios';

import { config } from '../config';

function regions() {
  return axios
    .get(`${config.apiUrl}/v1/regions`)
    .then(response => response.data);
}

function elevators() {
  return axios
    .get(`${config.apiUrl}/v1/elevators`)
    .then(response => response.data);
}

function currencies() {
  return axios
    .get(`${config.apiUrl}/v1/currencies`)
    .then(response => response.data);
}

function cultures() {
  return axios
    .get(`${config.apiUrl}/v2/cultures`)
    .then(response => response.data);
}

export default {
  regions,
  elevators,
  currencies,
  cultures,
};
