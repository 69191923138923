import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPaginate from 'react-paginate';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';

const styles = theme => ({
  root: {
    display: 'flex',
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  pageLink: {
    color: theme.palette.grey[800],
    height: '100%',
    paddingLeft: '8px',
    paddingRight: '8px',
    display: 'flex',
    alignItems: 'center',
    outline: 'none',
  },
  breakLink: {
    pointerEvents: 'none',
    userSelect: 'none',
  },
  activeLink: {
    fontWeight: '600',
    marginLeft: '4px',
    marginRight: '4px',
    pointerEvents: 'none',
  },
  disabledLink: {
    pointerEvents: 'none',
    opacity: '0.5',
  },
});

const Pagination = ({
  classes,
  pageRangeDisplayed,
  marginPagesDisplayed,
  containerClassName,
  pageLinkClassName,
  breakLinkClassName,
  activeLinkClassName,
  disabledClassName,
  ...props
}) => (
  <ReactPaginate
    pageRangeDisplayed={pageRangeDisplayed}
    marginPagesDisplayed={marginPagesDisplayed}
    containerClassName={classNames(classes.root, containerClassName)}
    pageLinkClassName={classNames(classes.pageLink, pageLinkClassName)}
    breakLinkClassName={classNames(
      classes.pageLink,
      classes.breakLink,
      pageLinkClassName,
      breakLinkClassName,
    )}
    activeLinkClassName={classNames(classes.activeLink, activeLinkClassName)}
    disabledClassName={classNames(classes.disabledLink, disabledClassName)}
    previousLabel={
      <IconButton>
        <KeyboardArrowLeftIcon />
      </IconButton>
    }
    nextLabel={
      <IconButton>
        <KeyboardArrowRightIcon />
      </IconButton>
    }
    // breakLabel={
    //   <IconButton>
    //     <MoreHorizIcon />
    //   </IconButton>
    // }
    {...props}
  />
);

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number,
  marginPagesDisplayed: PropTypes.number,

  containerClassName: PropTypes.string,
  pageLinkClassName: PropTypes.string,
  breakLinkClassName: PropTypes.string,
  activeLinkClassName: PropTypes.string,
  disabledClassName: PropTypes.string,

  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
};

Pagination.defaultProps = {
  pageRangeDisplayed: 3,
  marginPagesDisplayed: 1,

  containerClassName: '',
  pageLinkClassName: '',
  breakLinkClassName: '',
  activeLinkClassName: '',
  disabledClassName: '',
};

export default withStyles(styles)(Pagination);
