import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { injectIntl } from 'react-intl';
import { withSnackbar } from 'notistack';

import { logout } from '../store/actions';

class AxiosInterceptors extends React.PureComponent {
  static propTypes = {
    user: PropTypes.shape({
      authdata: PropTypes.string,
    }).isRequired,
    logout: PropTypes.func.isRequired,

    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
    enqueueSnackbar: PropTypes.func.isRequired,
  };

  interceptors = {
    setAuthInterceptor: null,
    logoutInterceptor: null,
  };

  componentDidMount() {
    this.applyInterceptors();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.authdata === prevProps.user.authdata) {
      return;
    }

    this.applyInterceptors();
  }

  componentWillUnmount() {
    this.ejectInterceptors();
  }

  // Set Authorization header is there is user
  createSetAuthInterceptor = user => config => {
    if (user.authdata) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Basic ${user.authdata}`;
    }
    return config;
  };

  createLogoutInterceptor = () => error => {
    const { logout, intl, enqueueSnackbar } = this.props;
    let errorMessage = '';
    console.dir(error);

    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      const { response } = error;

      if (response.data.error) {
        errorMessage = response.data.error;
      } else if (response.status === 403) {
        errorMessage = intl.formatMessage({ id: 'forms.error403' });
      }

      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });

      logout();
    }

    return Promise.reject(error);
  };

  applyInterceptors = () => {
    this.ejectInterceptors();

    const setAuthInterceptor = axios.interceptors.request.use(
      this.createSetAuthInterceptor(this.props.user),
      error => Promise.reject(error),
    );
    const logoutInterceptor = axios.interceptors.response.use(
      null,
      this.createLogoutInterceptor(),
    );

    this.interceptors = {
      setAuthInterceptor,
      logoutInterceptor,
    };
  };

  ejectInterceptors = () => {
    const { setAuthInterceptor, logoutInterceptor } = this.interceptors;

    axios.interceptors.request.eject(setAuthInterceptor);
    axios.interceptors.response.eject(logoutInterceptor);
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = { logout };

export default compose(
  injectIntl,
  withSnackbar,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AxiosInterceptors);
