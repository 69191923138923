import axios from 'axios';

import { config } from '../config';

function all(date) {
  return axios
    .get(`${config.apiUrl}/v2/prices`, {
      params: {
        sort: 'elevator',
        with: 'currency,elevator',
        date,
      },
    })
    .then(response => response.data);
}

export default {
  all,
};
