import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Tooltip from '@material-ui/core/Tooltip';

const GoBack = props => {
  const { onClick, intl, ...rest } = props;

  return (
    <Tooltip title={<FormattedMessage id="common.goBack" />}>
      <IconButton
        aria-label={intl.formatMessage({ id: 'common.goBack' })}
        onClick={onClick}
        className="mr-3"
        {...rest}
      >
        <ArrowBackIcon />
      </IconButton>
    </Tooltip>
  );
};

GoBack.propTypes = {
  onClick: PropTypes.func,

  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

GoBack.defaultProps = {
  onClick: () => null,
};

export default injectIntl(GoBack);
