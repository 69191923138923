import {
  PRICES_REQUEST,
  PRICES_SUCCESS,
  PRICES_FAILURE,
  PRICES_FILTER,
  PRICES_SET_TODAY_CLOSEST_DATE,
} from '../types';

import api from '../../api';

export const pricesRequest = () => ({
  type: PRICES_REQUEST,
});

export const pricesSuccess = ({ prices, date }) => ({
  type: PRICES_SUCCESS,
  prices,
  date,
});

export const pricesFailure = error => ({
  type: PRICES_FAILURE,
  error,
});

export const pricesFilter = filter => ({
  type: PRICES_FILTER,
  filter,
});

export const pricesSetTodayClosestDate = date => ({
  type: PRICES_SET_TODAY_CLOSEST_DATE,
  todayClosestDate: date,
});

export const fetchPrices = date => dispatch => {
  dispatch(pricesRequest());

  return api.prices
    .all(date)
    .then(prices => {
      dispatch(pricesSuccess(prices));

      if (!date) {
        const { date } = prices;
        dispatch(pricesSetTodayClosestDate(date));
      }
    })
    .catch(error => {
      dispatch(pricesFailure(error));
    });
};
