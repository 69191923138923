export function utf8_to_b64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export function b64_to_utf8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export function blob_to_b64(blob) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);

  return new Promise(resolve => {
    reader.onloadend = () => {
      const base64data = reader.result;

      resolve(base64data);
    };
  });
}
