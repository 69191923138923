import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { getColorFromIndex } from '../../../../helpers';

const styles = () => ({
  userBox: {
    '&:hover $userName': {
      textDecoration: 'underline',
    },
  },
  userName: {},
});

const InfoBox = props => {
  const {
    offer,
    isOfferSeller,
    isOfferBuyer,
    isBuyerCancelRequest,
    isSellerCancelRequest,
    classes,
  } = props;
  const { bought_at, expires_at, buyer, offer_state: offerState } = offer;

  const expiresAt = moment(expires_at);
  const boughtAt = moment(bought_at);

  return (
    <>
      {isOfferSeller && bought_at && (
        <div className="d-flex align-items-center">
          <CardHeader
            avatar={
              <Avatar
                src={buyer.avatar}
                alt={buyer.name}
                style={{
                  backgroundColor: !buyer.avatar
                    ? getColorFromIndex(buyer.id)[600]
                    : null,
                }}
              >
                {buyer.name[0]}
              </Avatar>
            }
            title={buyer.name}
            subheader={<FormattedMessage id="offers.boughtYourOffer" />}
            component={Link}
            to={`/user${buyer.id}`}
            classes={{ root: classes.userBox, title: classes.userName }}
            className="px-0 mr-3"
          />
          <div className="ml-a">
            <Typography color="textSecondary">
              {boughtAt.format('DD.MM.YY HH:mm')}
            </Typography>
          </div>
        </div>
      )}
      <Typography component="div">
        <>
          {!bought_at && offerState !== 'cancelled' && (
            <React.Fragment>
              <FormattedMessage id="offers.offerExpires" />
              {`: `}
              <strong>{expiresAt.format('DD MMMM YY, HH:mm')}</strong>
            </React.Fragment>
          )}
          {isOfferBuyer &&
            bought_at &&
            offerState !== 'sold' &&
            offerState !== 'delivery' &&
            !isBuyerCancelRequest &&
            !isSellerCancelRequest && (
              <React.Fragment>
                <FormattedMessage id="offers.youHaveBookedAnOffer" />
                {`: `}
                <strong>{expiresAt.format('DD MMMM YY, HH:mm')}</strong>
              </React.Fragment>
            )}
          {((isOfferBuyer && isBuyerCancelRequest) ||
            (isOfferSeller && isSellerCancelRequest)) && (
            <div>
              <strong>
                <FormattedMessage id="offers.youHaveCancelledAnOffer" />
              </strong>
            </div>
          )}
          {isOfferBuyer && isSellerCancelRequest && (
            <div>
              <strong>
                <FormattedMessage id="offers.sellerHaveCancelledAnOffer" />
              </strong>
            </div>
          )}
          {isOfferSeller && isBuyerCancelRequest && (
            <div>
              <strong>
                <FormattedMessage id="offers.buyerHaveCancelledAnOffer" />
              </strong>
            </div>
          )}
        </>
      </Typography>
    </>
  );
};

InfoBox.propTypes = {
  offer: PropTypes.shape({
    expires_at: PropTypes.string,
  }).isRequired,
  isOfferSeller: PropTypes.bool,
  isOfferBuyer: PropTypes.bool,
  isBuyerCancelRequest: PropTypes.bool,
  isSellerCancelRequest: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
};

InfoBox.defaultProps = {
  isOfferSeller: false,
  isOfferBuyer: false,
  isBuyerCancelRequest: false,
  isSellerCancelRequest: false,
};

export default withStyles(styles)(InfoBox);
