import React from 'react';
import PricingTable from '../components/pages/pricing.js';

const Prices = () => (
  <div>
    <PricingTable />
  </div>
)

export default Prices;
