import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';
import { withFormik } from 'formik';

import { yup } from '../../../../inits';

const addAll = (amount, setFieldValue) => () => {
  setFieldValue('value', amount);
};

const DeliveryForm = props => {
  const {
    tonnage,
    /* eslint-disable react/prop-types */
    values,
    errors,
    isSubmitting,
    handleChange,
    handleSubmit,
    setFieldValue,
    /* eslint-enable react/prop-types */
  } = props;

  const { value } = values;

  return (
    <form
      className="d-flex align-items-start flex-wrap"
      onSubmit={handleSubmit}
    >
      <TextField
        autoFocus
        type="number"
        name="value"
        value={value}
        onChange={handleChange}
        label={<FormattedMessage id="offers.goodsAmount" />}
        placeholder="0"
        helperText={errors.value}
        error={!!errors.value}
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography variant="body1" color="textSecondary">
                <FormattedMessage id="offers.t" />
              </Typography>
            </InputAdornment>
          ),
        }}
        style={{
          width: '180px',
        }}
        className="mx-2 mb-2"
      />
      <Button
        variant="outlined"
        color="primary"
        className="mx-2 my-2"
        onClick={addAll(tonnage, setFieldValue)}
        disabled={isSubmitting}
      >
        <FormattedMessage id="offers.addAll" />
      </Button>
      <Button
        variant="contained"
        color="primary"
        className="mx-2 my-2"
        type="submit"
        disabled={!!errors.value || !value || isSubmitting}
      >
        <FormattedMessage id="offers.deliverGoods" />
      </Button>
    </form>
  );
};

DeliveryForm.propTypes = {
  /* eslint-disable react/no-unused-prop-types */
  onDelivery: PropTypes.func.isRequired,
  delivered: PropTypes.number.isRequired,
  /* eslint-enable react/no-unused-prop-types */
  tonnage: PropTypes.number.isRequired,
};

export default compose(
  withFormik({
    mapPropsToValues() {
      return {
        value: '',
      };
    },
    validationSchema: ({ delivered, tonnage }) =>
      yup.object().shape({
        value: yup
          .number()
          .min(delivered + 1)
          .max(tonnage),
        // .required(),
      }),

    handleSubmit({ value }, { props, resetForm }) {
      props.onDelivery(value)();
      resetForm();
    },
  }),
)(DeliveryForm);
