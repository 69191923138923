import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { withStyles } from '@material-ui/core/styles';

import Heading from './Heading';

const styles = () => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  quoteChange: {
    display: 'inline-block',
    paddingTop: '0.125rem',
    paddingBottom: '0.125rem',
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
    minWidth: '42px',
  },
  quoteChangeNegative: {
    color: '#fff',
    backgroundColor: 'red',
  },
  quoteChangePositive: {
    color: '#fff',
    backgroundColor: 'green',
  },
  tr: {
    height: '56px',
  },
});

const currencyArray = {
  Euronext: 'EUR',
  CBOT: 'USD',
};

const currencySignArray = {
  EUR: '€',
  USD: '$',
};

const getQueryChangeClassname = (change, classes) => {
  const changeNumber = parseFloat(change.replace(',', '.'));

  if (changeNumber < 0) {
    return classes.quoteChangeNegative;
  }

  if (changeNumber > 0) {
    return classes.quoteChangePositive;
  }

  return '';
};

const Market = ({ title, market, onGraphClick, classes }) => {
  const { futures, date } = market;

  const currency = currencyArray[title];
  const currencySign = currencySignArray[currency];

  return (
    <div>
      <Heading>
        <div className="h5">
          <FormattedMessage id="exchange.quotes" />
          {` ${title}`}
        </div>
      </Heading>
      {futures.map(future => (
        <CardContent key={future.title} className="px-0 pb-4">
          <Heading light>
            <span className="h6">{future.title}</span>
            <Typography
              component="span"
              color="textSecondary"
              variant="subtitle2"
              className="ml-2"
              inline
            >
              {currency}
              {`\u200A/\u200A`}
              <FormattedMessage id="offers.t" />
            </Typography>
          </Heading>
          <div className={`${classes.root}`}>
            <Table padding="checkbox">
              <TableBody>
                {future.quotes.map(quote => (
                  <TableRow key={quote.chart_url} className={classes.tr}>
                    <TableCell>{quote.expiry}</TableCell>
                    <TableCell align="right">
                      <nobr>{`${quote.value} ${currencySign}`}</nobr>
                    </TableCell>
                    <TableCell align="right">
                      <span
                        className={classNames(
                          classes.quoteChange,
                          getQueryChangeClassname(quote.change, classes),
                        )}
                      >
                        {quote.change}
                      </span>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                         component="a"
                         href={quote.chart_url}
                         target="_blank"
                       // onClick={onGraphClick({
                       //   market: title,
                       //   future: future.title,
                       //   expiry: quote.expiry,
                       //   graphUrl: quote.chart_url,
                       // })}
                      >
                        <ShowChartIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      ))}
      <Typography className="mt-3" color="textSecondary">
        <FormattedMessage id="exchange.updated" />
        {`: `}
        {moment(date).format('DD MMMM YYYY, HH:mm')}
      </Typography>
    </div>
  );
};

Market.propTypes = {
  title: PropTypes.node.isRequired,
  market: PropTypes.shape({
    futures: PropTypes.array,
    date: PropTypes.string,
  }).isRequired,
  onGraphClick: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
};

export default withStyles(styles)(Market);
