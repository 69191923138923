import {
  REFERENCES_REQUEST,
  REFERENCES_SUCCESS,
  REFERENCES_FAILURE,
} from '../types';

import api from '../../api';

export const referencesRequest = () => ({
  type: REFERENCES_REQUEST,
});

export const referencesSuccess = references => ({
  type: REFERENCES_SUCCESS,
  references,
});

export const referencesFailure = error => ({
  type: REFERENCES_FAILURE,
  error,
});

export const fetchReferences = () => dispatch => {
  dispatch(referencesRequest());

  const currenciesPromise = api.references.currencies();
  const regionsPromise = api.references.regions();
  const elevatorsPromise = api.references.elevators();
  const culturesPromise = api.references.cultures();

  return Promise.all([
    currenciesPromise,
    regionsPromise,
    elevatorsPromise,
    culturesPromise,
  ])
    .then(values => {
      const references = values.reduce((accu, item) => {
        const key = Object.keys(item)[0];
        const value = Object.values(item)[0];

        // it's fine to reassign accumularor in reduce
        // eslint-disable-next-line no-param-reassign
        accu[key] = value;
        return accu;
      }, {});

      dispatch(referencesSuccess(references));
    })
    .catch(error => {
      dispatch(referencesFailure(error));
    });
};
