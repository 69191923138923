import { createSelector } from 'reselect';
import { getReferences } from './referencesSelectors';

const getOffers = state => state.offers.data;

// Format offers

export const formatOffer = (offer, references) => {
  if (!offer || Object.keys(references).length <= 1) return {};

  const { elevator_id, culture_id, currency_id, region_id } = offer;

  const elevator = references.elevators.find(
    elevator => elevator.id === elevator_id,
  );

  const culture = references.cultures.find(
    culture => culture.id === culture_id,
  );

  const currency = references.currencies.find(
    currency => currency.id === currency_id,
  );

  const region = references.regions.find(region => region.id === region_id);

  return {
    ...offer,
    culture,
    currency,
    elevator: {
      region,
      ...elevator,
    },
  };
};

export const formatOffers = (offers, references) => {
  if (Object.keys(references).length <= 1 || !offers.length) return [];

  return offers.map(offer => formatOffer(offer, references));
};

export const getFormattedOffers = createSelector(
  [getOffers, getReferences],
  formatOffers,
);
