import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
// import Checkbox from 'material-ui/Checkbox';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withFormik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';

import { utf8_to_b64 } from '../../helpers';
import { yup } from '../../inits';

const LoginForm = ({
  values,
  errors,
  touched,
  isSubmitting,
  submitCount,
  handleChange,
  handleSubmit,
}) => (
  <React.Fragment>
    <form name="login-form" className="login-form" onSubmit={handleSubmit}>
      <div className="login-wrapper">
        <div className="login-fields">
          <h3>
            <FormattedMessage id="auth.login.heading" />
            {/* 
          <Link to="/forgot-password">
            <FormattedMessage id="auth.login.forgotPassword" />
          </Link> 
          */}
          </h3>
          <TextField
            id="email"
            name="email"
            type="email"
            fullWidth
            disabled={isSubmitting}
            onChange={handleChange}
            value={values.email}
            label="E-mail"
            placeholder="example@domain.com"
            helperText={(submitCount || touched.email) && errors.email}
            error={(submitCount || touched.email) && !!errors.email}
            margin="normal"
          />
          <TextField
            id="password"
            name="password"
            fullWidth
            disabled={isSubmitting}
            onChange={handleChange}
            value={values.password}
            label={<FormattedMessage id="forms.password" />}
            helperText={(submitCount || touched.password) && errors.password}
            error={(submitCount || touched.password) && !!errors.password}
            margin="normal"
          />
          {/* <div className="pt20">
          <Checkbox label={<FormattedMessage id="auth.login.rememberMe" />} />
        </div> */}
          <div className="pt30">
            <Button
              disabled={isSubmitting}
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
            >
              {isSubmitting ? (
                <CircularProgress thickness={5} size={24} />
              ) : (
                <FormattedMessage id="auth.login.enter" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </form>
  </React.Fragment>
);

LoginForm.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  submit: PropTypes.func.isRequired,

  isSubmitting: PropTypes.bool,
  submitCount: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }).isRequired,
  touched: PropTypes.shape({
    email: PropTypes.bool,
    password: PropTypes.bool,
  }).isRequired,
};

LoginForm.defaultProps = {
  isSubmitting: false,
  submitCount: 0,
};

const LoginFormik = withFormik({
  mapPropsToValues() {
    return {
      email: '',
      password: '',
    };
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    password: yup
      .string()
      .min(6)
      .required(),
  }),
  handleSubmit({ email, password }, { props, setSubmitting, setErrors }) {
    const authdata = utf8_to_b64(`${email}:${password}`);

    props.submit(authdata).catch(error => {
      // eslint-disable-next-line no-console
      console.dir(error);
      setSubmitting(false);

      let errorMessage = '';

      if (error && error.response) {
        const { response } = error;

        if (response.data.error) {
          errorMessage = response.data.error;
        } else if (response.status === 403) {
          errorMessage = props.intl.formatMessage({ id: 'forms.error403' });
        }
      } else if (error && error.message) {
        errorMessage = error.message;
      }

      setErrors({
        password: errorMessage,
      });
    });
  },
})(LoginForm);

export default injectIntl(LoginFormik);
