import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import { editSettings } from '../../store/actions';

class SettingsPage extends React.Component {
  onToggle = (event, isInputChecked) => {
    const { target } = event;
    const { editSettings } = this.props;

    const settings = {
      settings: {
        [target.dataset.group]: {
          [target.name]: isInputChecked,
        },
      },
    };

    editSettings(settings);
  };

  render() {
    const { settings, userId, intl } = this.props;
    const { notifications } = settings;

    const title = intl.formatMessage({ id: 'nav.settings' });
    const siteTitle = intl.formatMessage({ id: 'siteTitle' });

    return (
      <div className="readmin-panel profile-panel">
        <Helmet>
          <title>{`${title} | ${siteTitle}`}</title>
        </Helmet>
        <div className="panel-heading">
          <div className="panel-heading-buttons" style={{ marginLeft: 'auto' }}>
            <Link to={`/user${userId}`}>
              <IconButton>
                <AccountCircleIcon />
              </IconButton>
            </Link>
          </div>
        </div>
        <div className="panel-body">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="pt20 pb20">
                <FormGroup row>
                  <FormControlLabel
                    labelPlacement="start"
                    control={
                      <Switch
                        checked={notifications.general_chat}
                        onChange={this.onToggle}
                        name="general_chat"
                        inputProps={{
                          'data-group': 'notifications',
                        }}
                      />
                    }
                    label={
                      <FormattedMessage id="settings.notifications.generalChat" />
                    }
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SettingsPage.propTypes = {
  settings: PropTypes.shape({
    notifications: PropTypes.object,
  }).isRequired,
  editSettings: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

const mapStateToProps = state => ({
  settings: state.settings,
  userId: state.user.id,
});

const mapDispatchToProps = {
  editSettings,
};

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SettingsPage);
