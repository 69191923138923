import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';

import AmountBox from './AmountBox';
import StatusBox from './StatusBox';
import DateDisplay from '../../ui/DateDisplay';
import { getColorFromIndex } from '../../../helpers';

const styles = () => ({
  offerHeader: {
    display: 'block',

    '&:hover $offerName': {
      textDecoration: 'underline',
      opacity: '0.75',
    },
  },
  offerName: {
    transition: 'opacity 0.25s ease',
  },
  statusBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '0.25rem',
    alignItems: 'center',
  },
  amountItem: {
    fontWeight: '400',
  },
  userBox: {
    '&:hover $userName': {
      textDecoration: 'underline',
    },
  },
  userName: {},
});

const getSubHeader = (region, elevator, city) => {
  if (region && elevator) return `${region.title}, ${elevator.title}`;
  if (region && !elevator) return `${region.title}, ${city}`;
  if (!region && elevator) return `${elevator.title}`;
  return '';
};

const Offer = props => {
  const { classes, data, offerLinkBuilder } = props;
  const {
    id,
    seller,
    offer_state,
    product,
    created_at,
    elevator,
    region,
    city,
  } = data;

  const createdAt = moment(created_at);
  const offerLink = offerLinkBuilder ? offerLinkBuilder(id) : `/offers/${id}`;

  return (
    <Card className={classes.card}>
      <CardContent
        className={classes.offerHeader}
        component={Link}
        to={offerLink}
      >
        <Typography variant="h6" component="h2" className={classes.offerName}>
          {product}
        </Typography>
        <div className={classes.statusBlock}>
          <Tooltip title={createdAt.format('DD MMMM YYYY, HH:mm:ss')}>
            <div>
              <DateDisplay date={createdAt.fromNow()} />
            </div>
          </Tooltip>
          <StatusBox offerState={offer_state} />
        </div>
      </CardContent>
      <AmountBox offer={data} amountItemTypoClass={classes.amountItem} />
      <CardHeader
        avatar={
          <Avatar
            src={seller.avatar}
            alt={seller.name}
            style={{
              backgroundColor: !seller.avatar
                ? getColorFromIndex(seller.id)[600]
                : null,
            }}
          >
            {seller.name[0]}
          </Avatar>
        }
        title={seller.name}
        subheader={getSubHeader(region, elevator, city)}
        component={Link}
        to={`/user${seller.id}`}
        classes={{ root: classes.userBox, title: classes.userName }}
      />
    </Card>
  );
};

Offer.propTypes = {
  classes: PropTypes.shape({
    statusBlock: PropTypes.string,
  }).isRequired,

  offerLinkBuilder: PropTypes.func,

  data: PropTypes.shape({
    seller: PropTypes.object,
    offer_state: PropTypes.string,
    product: PropTypes.string,
    tonnage: PropTypes.number,
    price: PropTypes.number,
    total_price: PropTypes.number,
    created_at: PropTypes.string,
    currency: PropTypes.object,
    elevator: PropTypes.object,
  }).isRequired,
};

Offer.defaultProps = {
  offerLinkBuilder: null,
};

export default withStyles(styles)(Offer);
