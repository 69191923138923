import { EXCHANGE_REQUEST, EXCHANGE_SUCCESS, EXCHANGE_FAILURE } from '../types';

const initState = {
  data: {},
  loading: false,
};

export default function exchange(state = initState, action = {}) {
  switch (action.type) {
    case EXCHANGE_REQUEST:
      return { ...state, loading: true };

    case EXCHANGE_SUCCESS:
      return {
        data: action.exchange,
        loading: false,
      };

    case EXCHANGE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
}
