import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider as V0MuiThemeProvider } from 'material-ui';
import { MuiThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ru';
import { SnackbarProvider } from 'notistack';

import { v0MuiTheme, theme } from '../themes';
import { translations } from '../lang';
import store from '../store';
import { login, userLoaded } from '../store/actions';

moment.locale('ru');

addLocaleData([...en, ...ru]);
const lang = 'ru-RU';

if (localStorage.user) {
  const user = JSON.parse(localStorage.user);
  store.dispatch(login(user.authdata));
} else {
  store.dispatch(userLoaded());
}

const Providers = ({ children }) => (
  <Provider store={store}>
    <IntlProvider locale={lang} messages={translations[lang]}>
      <MuiThemeProvider theme={theme}>
        <V0MuiThemeProvider muiTheme={v0MuiTheme}>
          <SnackbarProvider
            maxSnack={2}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            autoHideDuration={5000}
            action={[
              <IconButton key="close" aria-label="Close" color="inherit">
                <CloseIcon />
              </IconButton>,
            ]}
          >
            <MuiPickersUtilsProvider utils={MomentUtils} moment={moment}>
              {children}
            </MuiPickersUtilsProvider>
          </SnackbarProvider>
        </V0MuiThemeProvider>
      </MuiThemeProvider>
    </IntlProvider>
  </Provider>
);

Providers.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Providers;
