import {
  OFFERS_REQUEST,
  OFFERS_SUCCESS,
  OFFERS_FAILURE,
  OFFERS_FILTER,
  OFFERS_DROP,
} from '../types';

const initState = {
  data: [],
  filters: {},
  loading: false,
};

export default function offers(state = initState, action = {}) {
  const { error, ...errorlessState } = state;

  switch (action.type) {
    case OFFERS_REQUEST:
      return { ...state, loading: true };

    case OFFERS_SUCCESS:
      return {
        ...errorlessState,
        data: action.offers,
        date: action.date,
        loading: false,
      };

    case OFFERS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case OFFERS_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.filter },
      };

    case OFFERS_DROP:
      return initState;

    default:
      return state;
  }
}
