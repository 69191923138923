import { createSelector } from 'reselect';

export const getReferences = state => state.references.data;

export const isRefsLoaded = createSelector(
  [getReferences],
  references => !!Object.keys(references).length,
);

export const getCultures = createSelector(
  [getReferences],
  references => (references.cultures ? references.cultures : []),
);

export const getCurrencies = createSelector(
  [getReferences],
  references => (references.currencies ? references.currencies : []),
);

export const getRegions = createSelector(
  [getReferences],
  references => (references.regions ? references.regions : []),
);

export const getElevators = createSelector(
  [getReferences],
  references => (references.elevators ? references.elevators : []),
);

export const getSortedCultures = createSelector(
  [getCultures],
  cultures => sortAlph(cultures, 'title'),
);

// Helpers
function sortAlph(array, field) {
  return array.sort((a, b) => {
    const titleA = a[field].toLowerCase();
    const titleB = b[field].toLowerCase();
    if (titleA < titleB) return -1;
    if (titleA > titleB) return 1;
    return 0;
  });
}
