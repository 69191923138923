import React from 'react';
import MailConfirmation from '../components/other-pages/mailConfirm.js';

const MailConfirmPage = () => (
  <div>
    <MailConfirmation />
  </div>

);

export default MailConfirmPage;
