import {
  REFERENCES_REQUEST,
  REFERENCES_SUCCESS,
  REFERENCES_FAILURE,
} from '../types';

const initState = {
  data: {},
};

export default function references(state = initState, action = {}) {
  switch (action.type) {
    case REFERENCES_REQUEST:
      return state;

    case REFERENCES_SUCCESS:
      return {
        data: action.references,
      };

    case REFERENCES_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
