import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';

const ActionsBox = props => {
  const {
    offerState,
    isUserBuyer,
    isOfferBuyer,
    isOfferSeller,
    isRequesting,
    isBuyerCancelRequest,
    isSellerCancelRequest,
    // hasBuyer,
    onBuy,
    onDiscard,
    onDeliveryStarted,
    onBuyerCancel,
    onSellerCancel,
    onBuyerRestore,
    onSellerRestore,
  } = props;

  return (
    <React.Fragment>
      {/* ================== */}
      {/* Start the purchase */}
      {/* ================== */}
      {offerState === 'active' && isUserBuyer && (
        <Button
          className="mx-2 my-2"
          variant="contained"
          color="secondary"
          onClick={onBuy}
          disabled={isRequesting}
        >
          <FormattedMessage id="offers.buy" />
        </Button>
      )}
      {offerState === 'bought' && isOfferSeller && (
        <Button
          className="mx-2 my-2"
          variant="contained"
          color="secondary"
          onClick={onDeliveryStarted}
          disabled={isRequesting}
        >
          <FormattedMessage id="offers.confirmOffer" />
        </Button>
      )}
      {(isOfferBuyer || isOfferSeller) && (
        <React.Fragment>
          {/* ============ */}
          {/* Cancellation */}
          {/* ============ */}
          {offerState === 'bought' && (
            <Button
              className="mx-2 my-2"
              // variant="outlined"
              onClick={onDiscard}
              disabled={isRequesting}
            >
              {isOfferSeller ? (
                <FormattedMessage id="offers.declineOffer" />
              ) : (
                <FormattedMessage id="offers.cancelPurchase" />
              )}
            </Button>
          )}
          {offerState === 'delivery' && (
            <>
              {isOfferBuyer && (
                <>
                  {!isBuyerCancelRequest && (
                    <Button
                      className="mx-2 my-2"
                      // variant="outlined"
                      onClick={onBuyerCancel}
                      disabled={isRequesting}
                    >
                      {isSellerCancelRequest && (
                        <FormattedMessage id="offers.confirmСancellation" />
                      )}
                      {!isSellerCancelRequest && (
                        <FormattedMessage id="offers.cancelOffer" />
                      )}
                    </Button>
                  )}
                  {isBuyerCancelRequest && (
                    <Button
                      className="mx-2 my-2"
                      variant="contained"
                      color="secondary"
                      onClick={onBuyerRestore}
                      disabled={isRequesting}
                    >
                      <FormattedMessage id="offers.restoreOffer" />
                    </Button>
                  )}
                </>
              )}
              {isOfferSeller && (
                <>
                  {!isSellerCancelRequest && (
                    <Button
                      className="mx-2 my-2"
                      // variant="outlined"
                      onClick={onSellerCancel}
                      disabled={isRequesting}
                    >
                      {isBuyerCancelRequest && (
                        <FormattedMessage id="offers.confirmСancellation" />
                      )}
                      {!isBuyerCancelRequest && (
                        <FormattedMessage id="offers.cancelOffer" />
                      )}
                    </Button>
                  )}
                  {isSellerCancelRequest && (
                    <Button
                      className="mx-2 my-2"
                      variant="contained"
                      color="secondary"
                      onClick={onSellerRestore}
                      disabled={isRequesting}
                    >
                      <FormattedMessage id="offers.restoreOffer" />
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </React.Fragment>
      )}
      {/* =================== */}
      {/* Write to each other */}
      {/* =================== */}
      {/* {isUserBuyer && (
        <Button className="mx-2 my-2" color="primary">
          <FormattedMessage id="offers.writeToSeller" />
        </Button>
      )}
      {isOfferSeller && hasBuyer && (
        <Button className="mx-2 my-2" color="primary">
          <FormattedMessage id="offers.writeToBuyer" />
        </Button>
      )} */}
    </React.Fragment>
  );
};

ActionsBox.propTypes = {
  offerState: PropTypes.string.isRequired,
  isRequesting: PropTypes.bool,
  isBuyerCancelRequest: PropTypes.bool,
  isSellerCancelRequest: PropTypes.bool,
  isUserBuyer: PropTypes.bool,
  isOfferBuyer: PropTypes.bool,
  isOfferSeller: PropTypes.bool,
  // hasBuyer: PropTypes.bool,
  onBuy: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  onDeliveryStarted: PropTypes.func.isRequired,
  onBuyerCancel: PropTypes.func.isRequired,
  onSellerCancel: PropTypes.func.isRequired,
  onBuyerRestore: PropTypes.func.isRequired,
  onSellerRestore: PropTypes.func.isRequired,
};

ActionsBox.defaultProps = {
  isRequesting: false,
  isBuyerCancelRequest: false,
  isSellerCancelRequest: false,
  isUserBuyer: false,
  isOfferBuyer: false,
  isOfferSeller: false,
  // hasBuyer: false,
};

export default ActionsBox;
