import React from 'react';
import Faq from '../components/pages/faq.js';

const FaqPage = () => (
  <div>
    <Faq />
  </div>
)

export default FaqPage;
