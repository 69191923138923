/*
 * filename: routers.js
 * mainly responsivle for all routes component
 * change and sidebar routlist menu item
 * */

import React, { Component } from 'react';
import Drawer from 'material-ui/Drawer';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import Fab from '@material-ui/core/Fab';
import FormatIndentDecreaseIcon from '@material-ui/icons/FormatIndentDecrease';
import { FormattedMessage } from 'react-intl';

import LogoSvg from '../ui/LogoSvg';
import SideNav from '../routing/SideNav';
import Routes from '../routing/Routes';
import Footer from './Footer';
import googlePlaySvg from '../../static/img/GooglePlay.svg';
import appStoreSvg from '../../static/img/AppStore.svg';
import { config } from '../../config';

class AppLayout extends Component {
  constructor() {
    super();
    this.state = { menuOpen: true };
    this.menuCollapseWithResize = this.menuCollapseWithResize.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  // Sidebar collapse when tablet
  componentDidMount() {
    window.addEventListener('resize', this.menuCollapseWithResize);

    if (window.innerWidth < 1199.5) {
      this.setState({ menuOpen: false });
    }
  }

  // Sidebar collapse when tablet
  componentWillUnmount() {
    window.removeEventListener('resize', this.menuCollapseWithResize);
  }

  // menu collapse when on mobile function
  menuCollapseWithResize = () => {
    if (window.innerWidth < 1199.5) {
      this.setState({ menuOpen: false });
    }
    if (window.innerWidth > 1199.5) {
      this.setState({ menuOpen: true });
    }
  };

  // Sidebar toggle
  toggleMenu = () => {
    this.setState(prevState => ({
      menuOpen: !prevState.menuOpen,
    }));
  };

  onListItemClick = () => {
    if (window.innerWidth < 1199.5) {
      this.setState({ menuOpen: false });
    }
  };

  render() {
    const { menuOpen } = this.state;
    // Page content class change based on menu toggle
    const pageContent = classNames({
      'readmin-page-content': true,
      'menu-open': menuOpen,
    });

    // Sidebar class based on bg color
    const sidebarClass = classNames({
      'menu-drawer': true,
      'has-bg': true,
    });

    // header left part with logo and toggle button
    const HeaderLogoWithMenu = () => (
      <div className="an-header">
        <div className="header-left">
          <Link className="logo-box" to="/">
            <LogoSvg
              height="40"
              width="40"
              className="logo"
              imgProps={{
                style: {
                  fill: '#fff',
                },
              }}
              bgProps={{
                style: {
                  fill: 'none',
                },
              }}
            />
            <div className="brand">
              <FormattedMessage id="siteTitle" />
            </div>
          </Link>
          <Fab
            size="small"
            color="secondary"
            onClick={this.toggleMenu}
            style={{ flexShrink: 0 }}
          >
            <FormatIndentDecreaseIcon />
          </Fab>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div className="readmin-sidebar">
          <HeaderLogoWithMenu />
          <Drawer
            open={menuOpen}
            className={sidebarClass}
            containerClassName="sidebar-initial-color"
          >
            <Scrollbars autoHide>
              <div className="sidebar-inner">
                <SideNav onListItemClick={this.onListItemClick} />
                <div className="applinks-block py-3 px-3">
                  <a
                    className="applinks-item"
                    href={config.googlePlayUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={googlePlaySvg} alt="Google play" />
                  </a>
                  <a
                    className="applinks-item"
                    href={config.appStoreUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={appStoreSvg} alt="App Store" />
                  </a>
                </div>
              </div>
            </Scrollbars>
          </Drawer>
        </div>
        <div className={pageContent}>
          <Routes />
          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default AppLayout;
