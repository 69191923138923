import {
  OFFERS_REQUEST,
  OFFERS_SUCCESS,
  OFFERS_FAILURE,
  OFFERS_FILTER,
  OFFERS_DROP,
} from '../types';

import api from '../../api';

export const offersRequest = () => ({
  type: OFFERS_REQUEST,
});

export const offersSuccess = ({ offers, date }) => ({
  type: OFFERS_SUCCESS,
  offers,
  date,
});

export const offersFailure = error => ({
  type: OFFERS_FAILURE,
  error,
});

export const offersFilter = filter => ({
  type: OFFERS_FILTER,
  filter,
});

export const offersDrop = () => ({
  type: OFFERS_DROP,
});

export const fetchOffers = params => dispatch => {
  dispatch(offersRequest());

  return api.offers
    .list(params)
    .then(data => {
      dispatch(offersSuccess(data));
    })
    .catch(error => {
      dispatch(offersFailure(error));
    });
};
