/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { FormattedMessage } from 'react-intl';

const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',

    '& > div': {
      marginLeft: 0,
    },
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light'
        ? theme.palette.grey[300]
        : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 0,
    fontSize: 16,
    opacity: '0.42',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  const { hasValue, isDisabled } = props;
  const InputLabelProps = hasValue
    ? {
        InputLabelProps: {
          ...props.selectProps.textFieldProps.InputLabelProps,
          shrink: true,
        },
      }
    : null;

  return (
    <TextField
      disabled={isDisabled}
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
      {...InputLabelProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  const { isFocused, selectProps } = props;

  if (
    !isFocused &&
    (selectProps.textFieldProps && selectProps.textFieldProps.label)
  ) {
    return null;
  }

  return (
    <Typography
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div className={props.selectProps.classes.valueContainer}>
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

// function Menu(props) {
//   return (
//     <Paper
//       square
//       className={props.selectProps.classes.paper}
//       {...props.innerProps}
//     >
//       {props.children}
//     </Paper>
//   );
// }

const Autocomplete = props => {
  const {
    selectStyles: selectStylesProp,
    components: componentsProp,
    selectTheme,
    disabled,
    classes,
    theme,
    ...other
  } = props;

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    container: base => ({
      ...base,
      maxWidth: '100%',
    }),
    clearIndicator: base => ({
      ...base,
      paddingTop: '6px',
      paddingBottom: '6px',
    }),
    dropdownIndicator: base => ({
      ...base,
      paddingTop: '6px',
      paddingBottom: '6px',
    }),
    ...selectStylesProp,
  };

  const themeBuilder = themeRS => ({
    ...themeRS,
    borderRadius: 0,
    colors: {
      ...themeRS.colors,
      primary: theme.palette.text.primary,
    },
    ...selectTheme,
  });

  const components = {
    Control,
    // Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    ...componentsProp,
  };

  return (
    <Select
      classes={classes}
      styles={selectStyles}
      components={components}
      theme={themeBuilder}
      isDisabled={disabled}
      menuPlacement="auto"
      minMenuHeight={240}
      noOptionsMessage={() => <FormattedMessage id="common.noResults" />}
      placeholder={<FormattedMessage id="common.select" />}
      {...other}
    />
  );
};

Autocomplete.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.object,
  }).isRequired,
};

export default withStyles(styles, { withTheme: true })(Autocomplete);
