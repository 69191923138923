import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import api from '../../api';
import OffersLayout from './dumb/OffersLayout';
import GoBack from '../ui/GoBack';

const ACTIVE_STATES = ['active', 'bought', 'delivery'];
const ARCHIVE_STATES = ['sold', 'cancelled'];

const stateType = {
  active: ACTIVE_STATES,
  archive: ARCHIVE_STATES,
};

const stateTitle = {
  active: <FormattedMessage id="profile.activeOffers" />,
  archive: <FormattedMessage id="profile.archivedOffers" />,
};

class UserOffersPage extends React.Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object,
    }).isRequired,
    history: PropTypes.shape({
      goBack: PropTypes.func,
    }).isRequired,
  };

  state = {
    isLoading: false,
    offers: [],
    userName: '',
  };

  componentDidMount() {
    const { match } = this.props;
    const { id, state } = match.params;

    this.setState({ isLoading: true });

    api.offers
      .userList(id, {
        state: stateType[state].toString(),
      })
      .then(offers => {
        this.setState({ offers, isLoading: false });
      });

    api.user.view(id).then(user => {
      this.setState({ userName: user.name });
    });
  }

  onGoBack = () => {
    const { match, history } = this.props;
    const { id } = match.params;

    history.push(`/user${id}`);
  };

  offerLinkBuilder = id => {
    const { match } = this.props;

    return {
      pathname: `/offers/${id}`,
      state: {
        backTo: match.url,
      },
    };
  };

  render() {
    const { match } = this.props;
    const { userName, offers, isLoading } = this.state;
    const { state } = match.params;

    return (
      <OffersLayout
        title={
          <div className="d-flex align-items-center">
            <GoBack onClick={this.onGoBack} />
            <div>
              {userName}
              {` / `}
              {stateTitle[state]}
            </div>
          </div>
        }
        offers={offers}
        isLoading={isLoading}
        panelHeadingClass="pl-2"
        offerLinkBuilder={this.offerLinkBuilder}
      />
    );
  }
}

export default UserOffersPage;
