import {combineReducers} from 'redux';
import notificationReducers from './notificationReducers.js';
import tableReducers from './tableReducers.js';
import areaChartReducers from './chart-reducers/chartReducers.js';
import lineChartReducers from './chart-reducers/lineChartReducers.js';
import tinyChartReducers from './chart-reducers/tinyChartReducers.js';
import productTableReducers from './productTableReducers.js';
import productsReducers from './productReducers.js';
import teamReducres from './teamReducres.js';
import serviceReducers from './serviceReducers.js';
import blogReducers from './blogReducers.js';

const allReducers = combineReducers({
  notifications: notificationReducers,
  tableData: tableReducers,
  areaChartData: areaChartReducers,
  lineChartData: lineChartReducers,
  tinyChartData: tinyChartReducers,
  productsTable: productTableReducers,
  products: productsReducers,
  teams: teamReducres,
  services: serviceReducers,
  blogs: blogReducers,
});

export default allReducers;
