import React from 'react';
/*
 * filename: about.js
*/
import AboutUs from '../components/pages/aboutUs.js';

const About = () => {
  return (
    <div>
      <AboutUs />
    </div>
  );
}

export default About;
