import React from 'react';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ isAllowed, component: Component, redirectTo, ...rest }) => (
  <Route {...rest} render={props => (
    isAllowed
      ? <Component {...props} />
      : <Redirect to={{
        pathname: redirectTo || '/login',
        state: { from: props.location } }}
        />
  )} />
);
