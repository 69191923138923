import React from 'react';
import { FormattedMessage } from 'react-intl';

const currentYear = new Date().getFullYear();

const Footer = () => (
  <footer className="readmin-footer">
    <p>
      {`© `}
      <FormattedMessage id="siteTitle" />
      {` ${currentYear}. `}
      {`Made in `}
      <a
        href="https://amida.software/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Amida
      </a>
    </p>
  </footer>
);

export default Footer;
