// import React from 'react';
import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withSnackbar } from 'notistack';

import { injectIntl } from 'react-intl';
import {
  activateProfile,
  editProfile,
  uploadAvatar,
} from '../../../store/actions';
import { b64_to_utf8 } from '../../../helpers';
import { yup } from '../../../inits';
import ProfileForm from './ProfileForm';

export default compose(
  connect(
    state => ({ user: state.user }),
    {
      activateProfile,
      editProfile,
      uploadAvatar,
    },
  ),

  withSnackbar,
  injectIntl,

  withFormik({
    mapPropsToValues({ user: { name, email, phone } }) {
      return {
        avatar: null,
        name,
        email,
        phone,
        currentPassword: '',
        password: '',
        confirmPassword: '',
      };
    },
    validationSchema: ({ user }) => {
      const authdata = b64_to_utf8(user.authdata).split(':');
      const password = authdata[1];

      return yup.object().shape({
        name: yup
          .string()
          .min(6)
          .required(),
        email: yup
          .string()
          .email()
          .required(),
        phone: yup.string().phone(),
        currentPassword: yup.string().when('password', {
          is: val => val && !!val.length,
          then: yup
            .string()
            .password(password)
            .required(),
          otherwise: yup.string(),
        }),
        password: yup
          .string()
          .notPrevPassword(password)
          .min(6),
        confirmPassword: yup
          .string()
          .equalTo(yup.ref('password'))
          .min(6),
      });
    },
    handleSubmit(valuesFormik, bag) {
      const {
        avatar,
        confirmPassword,
        currentPassword,
        ...values
      } = valuesFormik;

      const { props, setSubmitting, setFieldValue, setErrors, resetForm } = bag;

      const authdata = b64_to_utf8(props.user.authdata).split(':');
      const credentials = {
        email: props.user.email,
        password: authdata[1],
      };

      const isInvited = props.user.state === 'invited';

      if (isInvited) {
        props.activateProfile(props.user.id, values, credentials, avatar);
      } else {
        props
          .editProfile(props.user.id, values, credentials)
          .then(() => {
            setSubmitting(false);
            resetForm();
            props.toggleEdit();

            const message = props.intl.formatMessage({
              id: 'forms.changesSaved',
            });

            props.enqueueSnackbar(message, {
              variant: 'success',
            });
          })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.dir(error);
            setSubmitting(false);
            // resetForm();

            if (error.response) {
              setErrors(error.response.data);
            }

            const errorMessage = props.intl.formatMessage({
              id: 'common.error',
            });

            props.enqueueSnackbar(errorMessage, {
              variant: 'error',
            });
          });

        if (avatar) {
          props
            .uploadAvatar(props.user.id, avatar)
            .then(() => {
              // to remove blinking effect
              setTimeout(() => {
                setFieldValue('avatar', null);
              }, 50);
            })
            .catch(error => {
              // eslint-disable-next-line no-console
              console.dir(error);
            });
        }
      }
    },
  }),
)(ProfileForm);
