import {
  PRICES_REQUEST,
  PRICES_SUCCESS,
  PRICES_FAILURE,
  PRICES_FILTER,
  PRICES_SET_TODAY_CLOSEST_DATE,
} from '../types';

const initState = {
  data: [],
  filters: {
    currency: null,
  },
  loading: false,
};

export default function prices(state = initState, action = {}) {
  const { error, ...errorlessState } = state;

  switch (action.type) {
    case PRICES_REQUEST:
      return { ...state, loading: true };

    case PRICES_SUCCESS:
      return {
        ...errorlessState,
        data: action.prices,
        date: action.date,
        loading: false,
      };

    case PRICES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case PRICES_FILTER:
      return {
        ...state,
        filters: { ...state.filters, ...action.filter },
      };

    case PRICES_SET_TODAY_CLOSEST_DATE:
      return {
        ...state,
        todayClosestDate: action.todayClosestDate,
      };

    default:
      return state;
  }
}
