import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchReferences } from '../store/actions';
import { isRefsLoaded } from '../store/selectors';

const withReferences = Component => {
  class WithReferences extends React.Component {
    static propTypes = {
      isRefsLoaded: PropTypes.bool.isRequired,
      fetchReferences: PropTypes.func.isRequired,
    };

    componentDidMount() {
      const { isRefsLoaded, fetchReferences } = this.props;

      if (!isRefsLoaded) {
        fetchReferences();
      }
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { isRefsLoaded, fetchReferences, props } = this.props;

      return <Component {...props} />;
    }
  }

  WithReferences.displayName = `withReferences(${Component.displayName ||
    Component.name ||
    'Component'})`;

  const mapStateToProps = state => ({
    isRefsLoaded: isRefsLoaded(state),
  });

  const mapDispatchToProps = {
    fetchReferences,
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WithReferences);
};

export { withReferences };
