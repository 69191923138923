import { combineReducers } from 'redux';

import demoReducers from '../../demo/reducers';
import userReducer from './userReducer';
import settingsReducer from './settingsReducer';
import pricesReducer from './pricesReducer';
import referencesReducer from './referencesReducer';
import offersReducer from './offersReducer';
import exchangeReducer from './exchangeReducer';

const rootReducer = combineReducers({
  demo: demoReducers,

  user: userReducer,
  settings: settingsReducer,
  prices: pricesReducer,
  references: referencesReducer,
  offers: offersReducer,
  exchange: exchangeReducer,
});

export default rootReducer;
