import React from 'react';
import PropTypes from 'prop-types';
import CardContent from '@material-ui/core/CardContent';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

import DeliveryProgress from './DeliveryProgress';
import DeliveryForm from './DeliveryForm';

const DeliveryBox = ({
  offerState,
  delivered,
  tonnage,
  confirmed,
  isOfferBuyer,
  isOfferSeller,
  isBuyerCancelRequest,
  isSellerCancelRequest,
  deliveryStartedAt,
  onDelivery,
  onDeliveryConfirmed,
  onSell,
  isRequesting,
}) =>
  (offerState === 'delivery' ||
    (offerState === 'sold' && confirmed === tonnage)) &&
  !isBuyerCancelRequest &&
  !isSellerCancelRequest &&
  deliveryStartedAt && (
    <CardContent>
      <DeliveryProgress
        delivered={delivered}
        confirmed={confirmed}
        tonnage={tonnage}
      />
      {delivered === 0 && (
        <Typography variant="button" className="text-center mt-3 mb-3">
          <FormattedMessage
            id="offers.expectedDelivery"
            values={{
              tonnage,
            }}
          />
        </Typography>
      )}
      {delivered > 0 && confirmed !== tonnage && (
        <Typography variant="button" className="text-center mt-3 mb-3">
          <FormattedMessage
            id="offers.ofDelivered"
            values={{
              confirmed,
              tonnage,
            }}
          />
        </Typography>
      )}
      {confirmed === tonnage && (
        <Typography variant="button" className="text-center mt-3 mb-3">
          <FormattedMessage id="offers.allGoodsDelivered" />
        </Typography>
      )}
      {isOfferBuyer && delivered === 0 && (
        <div className="d-flex justify-content-center">
          <Button variant="contained" color="primary" onClick={onSell}>
            <FormattedMessage id="offers.confirmFullDelivery" />
          </Button>
        </div>
      )}
      <Grow in={isOfferBuyer && delivered > confirmed} unmountOnExit>
        <div className="d-flex justify-content-center">
          <Button
            variant="outlined"
            color="primary"
            onClick={onDeliveryConfirmed(delivered)}
            disabled={isRequesting}
          >
            <FormattedMessage
              id="offers.confirmDelivery"
              values={{
                delivered: delivered - confirmed,
              }}
            />
          </Button>
        </div>
      </Grow>
      <Grow in={isOfferSeller && delivered !== tonnage} unmountOnExit>
        <DeliveryForm
          onDelivery={onDelivery}
          delivered={delivered}
          tonnage={tonnage}
        />
      </Grow>
    </CardContent>
  );

DeliveryBox.propTypes = {
  offerState: PropTypes.string.isRequired,
  delivered: PropTypes.number.isRequired,
  tonnage: PropTypes.number.isRequired,
  confirmed: PropTypes.number.isRequired,
  isOfferBuyer: PropTypes.bool.isRequired,
  isOfferSeller: PropTypes.bool.isRequired,
  isRequesting: PropTypes.bool,
  deliveryStartedAt: PropTypes.string,
  onDelivery: PropTypes.func.isRequired,
  onDeliveryConfirmed: PropTypes.func.isRequired,
};

DeliveryBox.defaultProps = {
  isRequesting: false,
  deliveryStartedAt: null,
};

export default DeliveryBox;
