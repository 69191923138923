import React from 'react';
import PropTypes from 'prop-types';

export default function PanelCentred({ height, children }) {
  return (
    <div className="readmin-panel" style={{ height }}>
      <div className="center-absolute">{children}</div>
    </div>
  );
}

PanelCentred.propTypes = {
  height: PropTypes.string,
  children: PropTypes.node.isRequired,
};

PanelCentred.defaultProps = {
  height: '300px',
};
