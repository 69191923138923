/* eslint no-template-curly-in-string: "off" */

import * as yup from 'yup';

const ru = {
  mixed: {
    default: 'Поле недействительно',
    required: 'Это обязательное поле',
    oneOf: 'Значение должно быть одним из следующих значений: ${values}',
    notOneOf: 'Значение не должно быть одним из следующих значений: ${values}',
    equalTo: '${path} должен равняться ${ref}',
  },
  string: {
    length: 'Длина строки должна быть точно ${length} символов',
    min: 'Длина строки должна быть по крайней мере ${min} символов',
    max: 'Длина строки должна быть не более ${max} символов',
    matches: 'Значение должно соответствовать следующему: "${regex}"',
    email: 'Введите правильный адрес электронной почты',
    phone: 'Введите действительный номер телефона. Например +38 077 777-77-77',
    password: 'Вы ввели неверный пароль',
    notPrevPassword: 'Новый пароль не должен совпадать с предыдущим',
    url: 'Значение должно быть действительным URL-адресом',
    trim: 'Значением должна быть обрезанная строка',
    lowercase: 'Значением должна быть строка нижнего регистра',
    uppercase: 'Значением должна быть строка верхнего регистра',
  },
  number: {
    min: 'Значение должно быть больше или равно ${min}',
    max: 'Значение должно быть меньше или равно ${max}',
    lessThan: 'Значение должно быть меньше ${less}',
    moreThan: 'Значение должно быть больше ${more}',
    notEqual: 'Значение не должно быть равно ${notEqual}',
    positive: 'Значение должно быть положительным числом',
    negative: 'Значение должно быть отрицательным числом',
    integer: 'Значение должно быть целым числом',
  },
  date: {
    min: 'Дата должна быть позже, чем ${min}',
    max: 'Дата должна быть раньше, чем ${max}',
  },
  object: {
    noUnknown: '${path} не может иметь ключи, не указанные в форме объекта',
  },
  array: {
    min: '${path} должно иметь по крайней мере ${min} элементов',
    max: '${path} поле должно иметь меньше или равно ${max} элементов',
  },
};

yup.setLocale(ru);

// Adding phone validation schema
yup.addMethod(yup.string, 'phone', function phoneValidation(message) {
  return this.test({
    name: 'phone',
    exclusive: true,
    message: message || ru.string.phone,
    test(value) {
      if (!value) return true; // make it unrequired

      return /^(?=\+3\W*8\W*0)\+\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d$/i.test(
        value,
      );
    },
  });
});

// Adding password validation schema

yup.addMethod(yup.string, 'password', function passwordValidation(
  password,
  message,
) {
  return this.test({
    name: 'password',
    exclusive: true,
    message: message || ru.string.password,
    test(value) {
      if (!value) return true; // make it unrequired

      return value === password;
    },
  });
});

yup.addMethod(yup.string, 'notPrevPassword', function notPrevPasswordValidation(
  password,
  message,
) {
  return this.test({
    name: 'notPrevPassword',
    exclusive: true,
    message: message || ru.string.notPrevPassword,
    test(value) {
      if (!value) return true; // make it unrequired

      return value !== password;
    },
  });
});

// Adding equalTo validation schema
yup.addMethod(yup.mixed, 'equalTo', function equalToValidation(ref, message) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: message || ru.mixed.equalTo,
    params: {
      ref: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
});

export { yup, ru };
