import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Grow from '@material-ui/core/Grow';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import Pagination from '../../common/Pagination';

import Loader from '../../ui/Loader';
import PanelCentred from '../../ui/PanelCentred';
import Offer from './Offer';

const styles = theme => ({
  title: {
    marginBottom: 0,
  },
  paginationBlock: {
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  pagination: {
    position: 'absolute',
    right: 0,

    [theme.breakpoints.down('md')]: {
      position: 'static',
      width: '100%',
      justifyContent: 'center',
      marginTop: '1.5rem',
    },
  },
});

const ITEMS_PER_PAGE = 24;

class OffersLayout extends React.Component {
  state = {
    itemsPerPage: ITEMS_PER_PAGE,
  };

  handlePageClick = data => {
    const { history, location } = this.props;
    const page = data.selected + 1;

    history.push({
      pathname: location.pathname,
      search: page !== 1 && `?page=${page}`,
    });

    this.setState({ itemsPerPage: ITEMS_PER_PAGE });
  };

  hrefBuilder = page => {
    const { match } = this.props;

    return `${match.url}?page=${page}`;
  };

  handleShowMoreClick = () => {
    this.setState(state => ({
      itemsPerPage: state.itemsPerPage + ITEMS_PER_PAGE,
    }));
  };

  render() {
    const {
      offers,
      isLoading,
      title,
      buttons,
      offerLinkBuilder,
      panelHeadingClass,
      classes,
      location,
    } = this.props;

    const { itemsPerPage } = this.state;

    // Get current page from address bar
    const params = new URLSearchParams(location.search);

    const currentPage = Number(params.get('page')) || 1;

    const indexOfFirstItem = currentPage * ITEMS_PER_PAGE - ITEMS_PER_PAGE;
    const indexOfLastItem = currentPage * itemsPerPage;
    const currentOffers = offers.slice(indexOfFirstItem, indexOfLastItem);

    // Logic for show more button
    const isEndReached = itemsPerPage * currentPage >= offers.length;

    // Logic for displaying offers pagination
    const totalPages = Math.ceil(offers.length / ITEMS_PER_PAGE);

    return (
      <div>
        <div className="readmin-panel">
          <div className={`panel-heading ${panelHeadingClass}`}>
            <div className={`${classes.title} h2`}>{title}</div>
            {buttons && <div className="panel-heading-buttons">{buttons}</div>}
          </div>
        </div>
        {isLoading && <Loader />}
        {!currentOffers.length && !isLoading && (
          <PanelCentred>
            <Typography>
              <FormattedMessage id="offers.noOffersYet" />
            </Typography>
          </PanelCentred>
        )}
        {!!currentOffers.length && (
          <React.Fragment>
            <Grid container spacing={24}>
              {currentOffers.map(offer => (
                <Grid key={offer.id} item xs={12} md={6} lg={4}>
                  <Offer data={offer} offerLinkBuilder={offerLinkBuilder} />
                </Grid>
              ))}
            </Grid>
            <div className={classes.paginationBlock}>
              <Grow in={!isEndReached} unmountOnExit>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={this.handleShowMoreClick}
                >
                  <FormattedMessage id="common.showMore" />
                </Button>
              </Grow>
              <Pagination
                pageCount={totalPages}
                onPageChange={this.handlePageClick}
                hrefBuilder={this.hrefBuilder}
                initialPage={currentPage - 1}
                disableInitialCallback
                containerClassName={classes.pagination}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

OffersLayout.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.node,
  buttons: PropTypes.node,
  offerLinkBuilder: PropTypes.func,
  panelHeadingClass: PropTypes.string,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
  }).isRequired,

  classes: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
};

OffersLayout.defaultProps = {
  isLoading: false,
  title: <FormattedMessage id="nav.offers" />,
  buttons: null,
  offerLinkBuilder: null,
  panelHeadingClass: '',
};

export default compose(
  withStyles(styles),
  withRouter,
)(OffersLayout);
