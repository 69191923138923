import { flattenMessages } from "../helpers";
import { enUS, ruRU } from "./locales";


const translations = {
  'en-US': {
    ...flattenMessages(enUS)
  },
  'ru-RU': {
    ...flattenMessages(ruRU)
  }
}


export { translations };
