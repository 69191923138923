import image from '../img/grid-large.jpg';

const text = 'Loren Ipsum Dolor sit amit'
export default function () {
  return [
    {
      id: 1,
      img: image,
      title: text,
      category: 'Design'
    },
    {
      id: 2,
      img: image,
      title: text,
      category: 'Development'
    },
    {
      id: 3,
      img: image,
      title: text,
      category: 'Movie'
    },
    {
      id: 4,
      img: image,
      title: text,
      category: 'Movie'
    },
    {
      id: 5,
      img: image,
      title: text,
      category: 'App'
    },
    {
      id: 6,
      img: image,
      title: text,
      category: 'Politics'
    }
  ]
}
