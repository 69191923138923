import React from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordPage = () => (
  <div>
    <ForgotPasswordForm />
  </div>
);

export default ForgotPasswordPage;
