import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  USER_EDITED_SETTINGS,
} from "../types";


export default function settings(state = {}, action = {}) {
  switch(action.type) {
    case USER_LOGGED_IN:
      return action.settings;

    case USER_LOGGED_OUT:
      return {};

    case USER_EDITED_SETTINGS:
      return {
        ...state,
        ...action.settings,
      };


    default: return state;
  }
}
