import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { FormattedMessage } from 'react-intl';

class ImagePicker extends Component {
  static propTypes = {
    editing: PropTypes.bool.isRequired,
    avatar: PropTypes.string,
    avatarBlob: PropTypes.instanceOf(Blob),
    onSave: PropTypes.func.isRequired,
  };

  static defaultProps = {
    avatar: '',
    avatarBlob: null,
  };

  state = {
    src: '',
    crop: {
      aspect: 1,
      width: 50,
      height: 50,
      x: 0,
      y: 0,
    },
    limitations: {
      maxHeight: 635,
      maxWidth: 635,
    },
    dialogOpen: false,
  };

  clearFileInput = e => {
    const input = e.target;
    input.value = null;
  };

  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result, dialogOpen: true }),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onCropChange = crop => {
    this.setState({ crop });
  };

  onCropComplete = (crop, pixelCrop) => {
    this.makeClientCrop(crop, pixelCrop);
  };

  onSaveCrop = () => {
    this.props.onSave(this.croppedImage);
    this.setState({
      dialogOpen: false,
    });
  };

  onDiscardCrop = () => {
    this.setState({ dialogOpen: false });
  };

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;

    // Make the library regenerate aspect crops if loading new images.
    const { crop } = this.state;

    if (crop.aspect && crop.height && crop.width) {
      this.setState({
        crop: { ...crop, height: null },
      });
    } else {
      this.makeClientCrop(crop, pixelCrop);
    }
  };

  makeClientCrop = async (crop, pixelCrop) => {
    if (this.imageRef && crop.width && crop.height) {
      this.croppedImage = await this.getCroppedImg(
        this.imageRef,
        pixelCrop,
        'newFile.jpeg',
      );
      this.croppedImageUrl = this.getImgUrl(this.croppedImage);
    }
  };

  getCroppedImg = (image, pixelCrop, fileName) => {
    const { limitations } = this.state;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width =
      pixelCrop.width > limitations.maxWidth
        ? limitations.maxWidth
        : pixelCrop.width;
    canvas.height =
      pixelCrop.height > limitations.maxHeight
        ? limitations.maxHeight
        : pixelCrop.height;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      canvas.width,
      canvas.height,
    );

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        // eslint-disable-next-line
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  getImgUrl = blob => {
    window.URL.revokeObjectURL(this.fileUrl);
    this.fileUrl = window.URL.createObjectURL(blob);
    return this.fileUrl;
  };

  render() {
    const { editing, avatarBlob } = this.props;
    const { crop, src } = this.state;

    const avatar = avatarBlob ? this.getImgUrl(avatarBlob) : this.props.avatar;

    return (
      <div>
        {avatar ? (
          <img className="img" src={avatar} alt="" />
        ) : (
          <AccountCircleIcon
            className="img"
            style={{
              display: 'block',
              width: '100%',
              height: 'auto',
              color: 'rgba(195, 195, 195, 0.87)',
            }}
          />
        )}
        <Grow in={editing} unmountOnExit>
          <Fab
            className="profile-upload"
            color="primary"
            size="medium"
            component="label"
          >
            <input
              type="file"
              className="d-none"
              onClick={this.clearFileInput}
              onChange={this.onSelectFile}
            />
            <CloudUploadIcon />
          </Fab>
        </Grow>

        <Dialog
          // scroll="paper"
          maxWidth="md"
          open={this.state.dialogOpen}
          onClose={this.onDiscardCrop}
        >
          <DialogContent>
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.onDiscardCrop}>
              <FormattedMessage id="forms.discard" />
            </Button>
            <Button color="primary" onClick={this.onSaveCrop} autoFocus>
              <FormattedMessage id="forms.save" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ImagePicker;
