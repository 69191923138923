import axios from 'axios';

import { config } from '../config';

function markets() {
  return axios
    .get(`${config.apiUrl}/v2/markets`)
    .then(response => response.data);
}

function currencyRates() {
  return axios
    .get(`${config.apiUrl}/v2/currencies/rates`)
    .then(response => response.data);
}

export default {
  markets,
  currencyRates,
};
