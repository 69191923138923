import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import PersonIcon from '@material-ui/icons/Person';
import Divider from '@material-ui/core/Divider';
import { FormattedMessage } from 'react-intl';

import { logout } from '../../store/actions';

class AvatarDropdown extends React.Component {
  state = {
    open: false,
  };

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  render() {
    const { logout, avatar, userId } = this.props;

    const { open } = this.state;

    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : undefined}
          aria-haspopup="true"
          buttonRef={node => {
            this.anchorEl = node;
          }}
          onClick={this.handleToggle}
          style={{
            marginLeft: '10px',
          }}
        >
          <Avatar
            src={avatar}
            style={{
              width: '35px',
              height: '35px',
              backgroundColor: !avatar ? '#147D14' : null,
            }}
          >
            {!avatar && <PersonIcon nativeColor="#fff" />}
          </Avatar>
        </IconButton>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
                position: 'relative',
                top: '6px',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    <Link to={`/user${userId}`}>
                      <MenuItem onClick={this.handleClose}>
                        <ListItemIcon>
                          <AccountCircleIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={<FormattedMessage id="nav.profile" />}
                        />
                      </MenuItem>
                    </Link>

                    <Divider />
                    <Link to="/settings">
                      <MenuItem onClick={this.handleClose}>
                        <ListItemIcon>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={<FormattedMessage id="nav.settings" />}
                        />
                      </MenuItem>
                    </Link>
                    <MenuItem onClick={logout}>
                      <ListItemIcon>
                        <PowerSettingsNewIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={<FormattedMessage id="auth.login.logout" />}
                      />
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  avatar: state.user.avatar,
  userId: state.user.id,
});
const mapDispatchToProps = { logout };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AvatarDropdown);
