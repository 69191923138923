import user1 from '../img/user1.jpg';
import user2 from '../img/user2.jpg';
import user3 from '../img/user3.jpg';

export default function () {
  return [
    {
      id: 1,
      name: 'Maxwell',
      role: 'Ceo',
      img: user1
    },
    {
      id: 2,
      name: 'EMMA ARIYA',
      role: 'Chief of design',
      img: user2
    },
    {
      id: 3,
      name: 'GILFO WILLIAM',
      role: 'Cto',
      img: user3
    }
  ]
}
