import { createSelector } from 'reselect';

const getPrices = state => state.prices.data;
const getFilters = state => state.prices.filters;

// Format prices
export const getFormattedPrices = createSelector(
  [getPrices],
  prices => {
    if (!prices.length) return [];

    return prices.map(price => {
      const { culture: culture_title, culture_id, elevator, currency } = price;

      const culture = {
        id: culture_id,
        title: culture_title,
      };

      return {
        value: price.value,
        culture,
        currency,
        elevator: {
          id: elevator.id,
          title: elevator.title,
          region: elevator.region.title,
        },
        region: {
          id: elevator.region.id,
          title: elevator.region.title,
        },
      };
    });
  },
);

export const getFilteredPrices = createSelector(
  [getFormattedPrices, getFilters],
  (prices, filters) => {
    const filtredPrices = prices.filter(price => {
      const keys = Object.keys(filters);
      const values = Object.values(filters);
      let count = 0;

      for (let i = 0; i < values.length; i++) {
        const filterName = keys[i];
        const filterValue = values[i];

        if (filterValue === null || price[filterName].id === filterValue.id) {
          count++;
        }
      }

      return count === values.length;
    });

    return filtredPrices;
  },
);

export const getPricesCultures = createSelector(
  [getFilteredPrices],
  prices =>
    onlyUnique(prices, 'culture', (result, culture) => {
      result.push({
        id: culture.id,
        title: culture.title,
        fullTitle: culture.fullTitle,
      });
    }),
);

export const getPricesElevators = createSelector(
  [getFilteredPrices],
  prices =>
    onlyUnique(prices, 'elevator', (result, elevator) => {
      result.push({
        id: elevator.id,
        title: elevator.title,
        region: elevator.region,
      });
    }),
);

export const getPricesCurrencies = createSelector(
  [getFormattedPrices],
  prices =>
    onlyUnique(prices, 'currency', (result, currency) => {
      result.push({
        id: currency.id,
        title: currency.title,
        signature: currency.signature,
      });
    }),
);

// Creates new array with unique elems of certain key

function onlyUnique(array, key, callback) {
  const result = [];
  const uniqIds = [];

  array.forEach(({ [key]: item }) => {
    if (uniqIds.indexOf(item.id) === -1) {
      uniqIds.push(item.id);

      callback(result, item);
    }
  });

  return result;
}
