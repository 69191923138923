import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Helmet } from 'react-helmet';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import Loader from '../ui/Loader';
import TabsContainer from '../ui/TabsContainer';
import Heading from './dumb/Heading';
import Market from './dumb/Market';
import { fetchExchange } from '../../store/actions';

const styles = theme => ({
  root: {
    '& $panelBody': {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: '15px',
        paddingRight: '15px',
      },
    },
  },
  panelBody: {},
  title: {
    marginBottom: 0,
  },
  tabs: {
    marginBottom: '-1px',
  },
  dialogTitle: {
    position: 'relative',
    paddingRight: '56px',
  },
  closeIcon: {
    position: 'absolute',
    right: '8px',
    top: '8px',
  },
});

const TabContent = ({ children }) => (
  <div className="row justify-content-center py-3">
    <div className="col-lg-8">{children}</div>
  </div>
);

TabContent.propTypes = {
  children: PropTypes.node.isRequired,
};

class ExchangePage extends Component {
  static propTypes = {
    fetchExchange: PropTypes.func.isRequired,
    markets: PropTypes.objectOf(
      PropTypes.shape({
        date: PropTypes.string,
        futures: PropTypes.array,
      }),
    ),
    currencyRates: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool.isRequired,

    fullScreen: PropTypes.bool.isRequired,
    classes: PropTypes.shape({
      root: PropTypes.string,
    }).isRequired,
    intl: PropTypes.shape({
      formatMessage: PropTypes.func,
    }).isRequired,
  };

  static defaultProps = {
    markets: {},
    currencyRates: [],
  };

  state = {
    activeTab: 0,
    openedGraphDialog: false,
    graphDialogTitle: '',
    graphUrl: '',
  };

  componentDidMount() {
    const { fetchExchange, currencyRates } = this.props;

    if (!currencyRates.length) {
      fetchExchange();
    }
  }

  handleChangeTab = (event, activeTab) => {
    this.setState({ activeTab });
  };

  handleOpenDialog = ({ market, future, expiry, graphUrl }) => () => {
    this.setState({
      graphUrl,
      graphDialogTitle: `${market}: ${future}, ${expiry}`,
      openedGraphDialog: true,
    });
  };

  handleCloseDialog = () => {
    this.setState({ openedGraphDialog: false });
  };

  render() {
    const {
      markets,
      currencyRates,
      isLoading,
      fullScreen,
      classes,
      intl,
    } = this.props;

    const {
      activeTab,
      openedGraphDialog,
      graphUrl,
      graphDialogTitle,
    } = this.state;

    const pageTitle = intl.formatMessage({ id: 'nav.exchange' });
    const siteTitle = intl.formatMessage({ id: 'siteTitle' });

    return (
      <div>
        <Helmet>
          <title>{`${pageTitle} | ${siteTitle}`}</title>
        </Helmet>
        <div className="readmin-panel">
          <div className="panel-heading">
            <div className={classNames(classes.title, `h2`)}>{pageTitle}</div>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className={classNames('readmin-panel', classes.root)}>
            <div className="panel-heading px-0 py-0 d-flex justify-content-center">
              <Tabs
                value={activeTab}
                onChange={this.handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                className={classes.tabs}
              >
                {Object.keys(markets).map(key => (
                  <Tab label={key} key={key} />
                ))}
                <Tab label={<FormattedMessage id="exchange.exchangeRates" />} />
              </Tabs>
            </div>
            <div className={`panel-body ${classes.panelBody}`}>
              <TabsContainer index={activeTab}>
                {Object.keys(markets).map(key => (
                  <TabContent key={key}>
                    <Market
                      title={key}
                      market={markets[key]}
                      onGraphClick={this.handleOpenDialog}
                    />
                  </TabContent>
                ))}
                <TabContent>
                  <Heading>
                    <div className="h5">
                      <FormattedMessage id="exchange.exchangeRates" />
                    </div>
                  </Heading>
                  {currencyRates.map((currRate, index, array) => (
                    <div key={currRate.pair}>
                      <CardContent className="d-flex justify-content-between">
                        <div className="text-black-50 mr-3">
                          {currRate.pair}
                        </div>
                        <div>{currRate.rate}</div>
                      </CardContent>
                      {index !== array.length - 1 && <Divider />}
                    </div>
                  ))}
                </TabContent>
              </TabsContainer>
            </div>
          </div>
        )}
        <Dialog
          open={openedGraphDialog}
          onClose={this.handleCloseDialog}
          scroll="paper"
          aria-labelledby="graph-dialog-title"
          fullScreen={fullScreen}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle
            id="graph-dialog-title"
            disableTypography
            className={classes.dialogTitle}
          >
            <Typography component="h2" variant="title">
              {graphDialogTitle}
            </Typography>
            <IconButton
              onClick={this.handleCloseDialog}
              className={classes.closeIcon}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className="text-center">
            <iframe
              src={graphUrl}
              frameBorder="0"
              title={graphDialogTitle}
              width="600"
              height="350"
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  markets: state.exchange.data.markets,
  currencyRates: state.exchange.data.currencyRates,
  isLoading: state.exchange.loading,
});

const mapDispatchToProps = {
  fetchExchange,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  injectIntl,
  withMobileDialog(),
)(ExchangePage);
