import React from 'react';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import yellow from '@material-ui/core/colors/yellow';
import { FormattedMessage } from 'react-intl';

const toFixedFloat = number => Math.round(number * 100) / 100;

export const styles = theme => ({
  /* Styles applied to the root element. */
  root: {
    position: 'relative',
    overflow: 'hidden',
    boxShadow: `inset 0 0 0 1px ${theme.palette.divider}`,
    height: `${theme.spacing.unit * 3}px`,
  },
  bar: {
    width: '100%',
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
    transition: 'width 0.2s linear',
    transformOrigin: 'left',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  bar1: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    zIndex: 2,
  },
  bar2: {
    backgroundColor: yellow[200],
    color: theme.palette.text.primary,
  },
  typo: {
    color: 'inherit',
  },
});

const DeliveryProgress = props => {
  const {
    classes,
    className: classNameProp,
    delivered,
    confirmed,
    tonnage,
    ...other
  } = props;

  const className = classNames(classes.root, classNameProp);

  const bar1 = classNames(classes.bar, classes.bar1);
  const bar2 = classNames(classes.bar, classes.bar2);

  const rootProps = {};
  const inlineStyles = { bar1: {}, bar2: {} };

  const confirmedPercent = (100 * confirmed) / tonnage;
  const deliveredPercent = (100 * delivered) / tonnage;

  rootProps['aria-valuenow'] = Math.round(deliveredPercent);
  inlineStyles.bar1.width = `${confirmedPercent}%`;
  inlineStyles.bar2 = {
    left: `${confirmedPercent}%`,
    width: `${deliveredPercent - confirmedPercent}%`,
  };

  return (
    <div className={className} role="progressbar" {...rootProps} {...other}>
      <div className={bar1} style={inlineStyles.bar1}>
        <Typography variant="button" className={classes.typo}>
          {confirmed}
          {` `}
          <FormattedMessage id="offers.t" />
        </Typography>
      </div>
      <div className={bar2} style={inlineStyles.bar2}>
        <Typography variant="button" className={classes.typo}>
          {toFixedFloat(delivered - confirmed)}
          {` `}
          <FormattedMessage id="offers.t" />
        </Typography>
      </div>
    </div>
  );
};

export default withStyles(styles)(DeliveryProgress);
