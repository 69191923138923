import axios from 'axios';

import { config } from '../config';

function login(authdata) {
  return axios
    .get(`${config.apiUrl}/v1/account`, {
      headers: {
        Authorization: `Basic ${authdata}`,
      },
    })
    .then(response => response.data);
}

function settings(settings) {
  return axios
    .patch(`${config.apiUrl}/v1/account`, settings)
    .then(response => response.data);
}

function activate(id, data) {
  return axios
    .patch(`${config.apiUrl}/v1/users/${id}`, data)
    .then(response => response.data);
}

function edit(id, data) {
  return axios
    .put(`${config.apiUrl}/v1/users/${id}`, data)
    .then(response => response.data);
}

function uploadAvatar(id, image) {
  return axios
    .put(`${config.apiUrl}/v1/users/${id}/avatar`, image)
    .then(response => response.data);
}

function view(id) {
  return axios
    .get(`${config.apiUrl}/v1/users/${id}`)
    .then(response => response.data.users[0]);
}

export default {
  login,
  settings,
  activate,
  edit,
  uploadAvatar,
  view,
};
