export const ruRU = {
  siteTitle: 'AgroFair',

  common: {
    from: 'из',
    sort: 'Сортировать',
    today: 'Сегодня',
    rowsPerPage: 'Cтрок на странице',
    noData: 'Нет данных',
    noResults: 'Нет результатов',
    error: 'Ошибка',
    showMore: 'Показать ещё',
    or: 'или',
    goBack: 'Вернуться',
    select: 'Выберите',
  },

  forms: {
    fullname: 'Имя и фамилия',
    fullnameHint: 'Сергей Сидоров',
    phone: 'Конт. телефон',
    submit: 'Подтвердить',
    discard: 'Отмена',
    save: 'Сохранить',
    password: 'Пароль',
    changesSaved: 'Изменения сохранены',
    error403: 'Нет доступа, скорее всего, ваш аккаунт заблокирован.',
  },

  nav: {
    offers: 'Заявки',
    profile: 'Профиль',
    messages: 'Сообщения',
    chats: 'Чаты',
    settings: 'Настройки',
    priceList: 'Прайс лист',
    exchange: 'Биржи',
  },

  auth: {
    login: {
      heading: 'Вход',
      forgotPassword: 'Забыли пароль?',
      enter: 'Войти',
      rememberMe: 'Запомнить меня',
      logout: 'Выйти',
    },
    forgotPassword: {
      enterYourEmail: 'Введите e-mail',
    },
  },

  profile: {
    activeOffers: 'Активные заявки',
    archivedOffers: 'Архив',
    userActivation: 'Активация пользователя',
    changePassword: 'Смена пароля',
    currentPassword: 'Текущий пароль',
    currentPasswordHint: 'Введите текущий пароль',
    newPassword: 'Новый пароль',
    newPasswordHint: 'Введите новый пароль',
    confirmPassword: 'Подтверждение пароля',
    confirmPasswordHint: 'Введите новый пароль ещё раз',
  },

  settings: {
    notifications: {
      generalChat: 'Уведомления в общем чате',
    },
  },

  prices: {
    subtitle: 'от',
    pickDate: 'Выберите дату',
    pickCurrency: 'Выберите валюту',
    allCurrencies: 'Все валюты',
    showToday: 'Показать за сегодня',
  },

  offers: {
    t: 'т',
    bought: 'Куплена',
    sold: 'Продана',
    cancelled: 'Отменена',
    delivery: 'Поставка',
    tonnage: 'Тоннаж',
    pricePerTon: 'Цена за тонну',
    totalСost: 'Общая стоимость',
    region: 'Область',
    elevator: 'Элеватор',
    city: 'Город',
    buy: 'Купить',
    writeToSeller: 'Написать продавцу',
    writeToBuyer: 'Написать покупателю',
    confirmOffer: 'Подтвердить сделку',
    cancelOffer: 'Отменить заявку',
    cancelPurchase: 'Отменить покупку',
    restoreOffer: 'Восстановить заявку',
    declineOffer: 'Отклонить предложение',
    confirmСancellation: 'Подтвердить отмену',
    offerExpires: 'Заявка истекает',
    boughtYourOffer: 'купил(a) вашу заявку',
    youHaveBookedAnOffer: 'Вы забронировали заявку до',
    youHaveCancelledAnOffer: 'Вы отменили заявку',
    buyerHaveCancelledAnOffer: 'Покупатель запросил отмену заявку',
    sellerHaveCancelledAnOffer: 'Продавец запросил отмену заявку',
    expectedDelivery: 'Ожидается поставка {tonnage} т',
    ofDelivered: '{confirmed} из {tonnage} т поставлено',
    waitingDeliveryConfirmation: 'Ожидаем подтверждения доставки',
    confirmDelivery: 'Подтвердить поставку {delivered} т.',
    confirmFullDelivery: 'Подтвердить полную поставку',
    allGoodsDelivered: 'Весь товар доставлен',
    goodsAmount: 'Количество товара',
    addAll: 'Добавить все',
    deliverGoods: 'Поставить товар',
    dealCompleted: 'Сделка завершена',
    createOffer: 'Создать заявку',
    extendTheOffer: 'Продлить срок заявки',
    offerExtended: 'Заявка успешно продлена',
    closeOffer: 'Закрыть заявку',
    offerIsClosed: 'Заявка {id} отменена',
    noOffersYet: 'Заявок пока нет',
  },

  createOffer: {
    newOffer: 'Новая заявка',
    culture: 'Культура',
    chooseCulture: 'Выберите культуру',
    description: 'Описание',
    enterDescription: 'Введите описание',
    currency: 'Валюта',
    cost: 'Стоимость',
    region: 'Область',
    chooseRegion: 'Выберите область',
    elevator: 'Элеватор',
    chooseElevator: 'Выберите элеватор',
    locality: 'Населённый пункт',
    enterTheLocality: 'Введите населённый пункт',
    expirationDate: 'Срок истечения',
    create: 'Создать',
    successCreation: 'Заявка успешно создана',
    errorCreation: 'Ошибка при создании заявки',
  },

  exchange: {
    exchangeRates: 'Курсы валют',
    quotes: 'Котировки',
    updated: 'Обновлено',
  },

  '404': {
    description: 'Страница, которую вы ищете, не найдена.',
    button: 'Вернуться на главную',
  },
};
